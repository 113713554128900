import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  Typography,
  Stack,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  addTrainingContent,
  addTrainingSection,
  getTrainingsSections,
  updateTrainingContent,
} from "src/pages/trainings/requests";
import { LoadingButton } from "@mui/lab";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const fullToolbarOptions = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ script: "sub" }, { script: "super" }],
  ["link", "image", "video"],
  ["clean"],
];

const AddTrainings = ({ trainingData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton);
  const isLoading2 = useSelector((state) => state.user.loadingButton2);

  const [editorValue, setEditorValue] = useState(
    trainingData?.description || ""
  );
  const [preview, setPreview] = useState(trainingData?.image ?? null);
  const [sectionName, setSectionName] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const trainingsSections = useSelector(
    (state) => state.education.trainingSections
  );

  const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    link: yup.string().url("Enter a valid URL").required("Link is required"),
    image: yup.mixed().when([], {
      is: () => !trainingData,
      then: (schema) => schema.required("Image is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: trainingData?.title || "",
      link: trainingData?.link || "",
    },
  });

  useEffect(() => {
    getTrainingsSections(dispatch);
  }, [dispatch]);

  const handleAddSection = () => {
    const payload = {
      name: sectionName,
    };
    addTrainingSection(dispatch, payload);
  };

  const onSubmit = (data) => {
    const sectionID = trainingsSections?.find(
      (item) => item?.name === selectedSection
    )?.id;

    const formData = new FormData();
    if (!trainingData || data?.image) {
      formData.append("image", data?.image);
    }
    formData.append("title", data?.title);
    formData.append("description", editorValue);
    formData.append("link", data?.link);
    formData.append("section_id", sectionID);

    if (trainingData) {
      updateTrainingContent(dispatch, trainingData?.id, formData);
    } else {
      addTrainingContent(dispatch, formData);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setValue("image", file);
      setPreview(URL.createObjectURL(file));
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" mb={2}>
        Add Training Content
      </Typography>

      <Stack
        direction="row"
        justifyContent={"space-between"}
        spacing={2}
        mb={3}
        alignItems="center"
      >
        <TextField
          label="Add Section Name"
          fullWidth
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
        />
        <Box>
          <Button
            variant="contained"
            fullWidth
            size="small"
            onClick={handleAddSection}
          >
            {isLoading2 ? "Loading..." : "Add"}
          </Button>
        </Box>
      </Stack>

      {trainingsSections?.length ? (
        <FormControl fullWidth margin="normal">
          <InputLabel>Select Section</InputLabel>
          <Select
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.target.value)}
          >
            {trainingsSections?.map((section, index) => (
              <MenuItem key={index} value={section?.name}>
                {section?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}

      <Stack direction={"row"} spacing={3} alignItems={"center"} my={2}>
        <Box mt={2}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="upload-image"
          />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              Upload Thumbnail
            </Button>
          </label>
        </Box>
        {!preview && errors.image && (
          <Typography color="error">Preview {errors.image.message}</Typography>
        )}

        {preview && (
          <Box mt={2}>
            <img
              src={preview}
              alt="Preview"
              style={{ width: 80, borderRadius: 8, marginTop: 8 }}
            />
          </Box>
        )}
      </Stack>

      {trainingsSections?.length ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Add Title"
            fullWidth
            {...register("title")}
            error={!!errors.title}
            helperText={errors.title?.message}
            required
            margin="normal"
          />

          <ReactQuill
            theme="snow"
            placeholder="Add Description"
            modules={{ toolbar: fullToolbarOptions }}
            value={editorValue}
            onChange={setEditorValue}
          />

          <TextField
            label="Training Video Link"
            fullWidth
            {...register("link")}
            error={!!errors.link}
            helperText={errors.link?.message}
            required
            margin="normal"
          />

          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            loading={isLoading}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
          >
            Submit
          </LoadingButton>
        </form>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AddTrainings;
