import React from "react";
import { NavLink } from "react-router-dom";
import { CallMade } from "@mui/icons-material";
import { Box, Typography, Button, Stack, Container } from "@mui/material";

const VideoSection = () => {
  const scrollToSection = (id) => {
    const section = document.getElementById(id.toLowerCase());
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          px: { xs: 2, md: 8 },
          py: { xs: 2, md: 4 },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            textAlign: "left",
          }}
        >
          Inner Circle’s Focus on What Truly Works
        </Typography>

        <Stack direction="row" spacing={2} alignItems="center" mt={2}>
          <Box>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Strategic Product Types
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#9795B5",
                mb: 2,
                textAlign: "left",
                fontSize: { md: "0.8rem", lg: "1rem" },
              }}
            >
              As a print on demand seller, you need to have access to the right
              product types. When you begin your research, you will quickly see
              that the product catalogs from other print providers are huge and
              can seem overwhelming. When you look through their products,
              you'll notice that the prices are too high, which makes it
              impossible to set your own prices and actually make any profit.
              That's why our approach is so effective. We only give you access
              to product types that are in high demand and offer great profit
              margins that other print on demand competitors can’t match. You
              can take a look at our catalog{" "}
              <NavLink to={"/catalog"}>here.</NavLink>
            </Typography>
          </Box>
        </Stack>

        <Stack direction="row" spacing={1} alignItems="flex-start" mt={1}>
          <Box>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Superior Education
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#9795B5",
                mb: 3,
                textAlign: "left",
                fontSize: { md: "0.8rem", lg: "1rem" },
              }}
            >
              To consistently generate sales and grow your POD business, it's
              essential to have the right understanding in place. Many sellers
              are unsure of what steps to take and often end up chasing the
              latest trend or even worse, another shiny object in the POD space.
              Our proven educational approach, when applied to your business,
              delivers substantial results again and AGAIN. Don’t believe me?
              Check out our user results{" "}
              <span
                style={{
                  color: "#02B2FE",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => scrollToSection("education")}
              >
                here.
              </span>
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <NavLink to="/signup">
                <Button variant="contained" endIcon={<CallMade />}>
                  Create Account
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default VideoSection;
