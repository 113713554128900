import React, { useEffect } from "react";
import { Box } from "@mui/material";

const VoomlyEmbed = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://embed.voomly.com/embed/embed-build.js";
    script.async = true;

    document.body.appendChild(script);

    // Cleanup if needed on unmount
    return () => {
      // If you want to remove it on unmount:
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        aspectRatio: "1.77778 / 1",
        background:
          "linear-gradient(45deg, rgb(142, 150, 164) 0%, rgb(201, 208, 222) 100%)",
        borderRadius: "10px",
      }}
      className="voomly-embed"
      data-id="u0QT0UY0AkTKiV0S0lJQu97bCYKDbjjqkY5Ny4BIOOsFFjQKj"
      data-ratio="1.777778"
      data-type="v"
      data-skin-color="rgba(2,178,254,1)"
      data-shadow=""
    />
  );
};

export default VoomlyEmbed;
