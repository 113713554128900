import { useEffect } from "react";
import { Box } from "@mui/material";
import {
  setPreviewImageSRC,
  setUploadedGraphicImage,
  setUploadedNotecardImage,
} from "src/redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { loadingAction } from "src/redux/actions/userActions";
import { setModalState } from "src/redux/actions/modalActions";
import { updateOrder } from "src/pages/orders/requests/icp/updateOrder";
import { initDynamicMockupsIframe } from "@dynamic-mockups/mockup-editor-sdk";

const DynamicMockupEditor = ({
  type,
  mockupUUID,
  orderID,
  selected,
  place,
}) => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);

  useEffect(() => {
    initDynamicMockupsIframe({
      iframeId: "dm-iframe",
      data: {
        // "x-website-key": "3rjxbnWasoW0", // localhost:3000
        "x-website-key":
          process.env.REACT_APP_ENVIRONMENT === "production"
            ? "skIzdRyMzUZF"
            : "in76lGXDcEc9",
        showColorPresets: false,
        showColorPicker: true,
        enableCollectionExport: false,
        showCollectionsWidget: true,
        mockupExportOptions: {
          image_format: "webp",
          image_size: 1080,
        },
        enableCreatePrintFiles: true,
        enableExportMockups: true,
        enableColorOptions: false,
        showSmartObjectArea: true,
        exportMockupsButtonText: orderID
          ? "Add Finalized Graphic"
          : "Add Design & Generate Thumbnail",
      },
      mode: "custom",
      callback: async (callbackData) => {
        dispatch(loadingAction(true));

        const mockupUrl =
          callbackData?.mockupsAndPrintFilesExport?.mockupsExport?.[0]
            ?.export_path;
        const printFileUrl =
          callbackData?.mockupsAndPrintFilesExport?.printFilesExport?.[0]
            ?.print_files?.[0]?.export_path;

        const fetchFile = async (url, fileName) => {
          if (!url) return null;
          try {
            const response = await fetch(url);
            const blob = await response.blob();
            return new File([blob], fileName, { type: blob.type });
          } catch (error) {
            return null;
          }
        };

        const mockupFile = await fetchFile(mockupUrl, "mockup.webp");
        const printFile = await fetchFile(printFileUrl, "print_file.png");

        if (mockupFile === null || printFile === null) {
          return;
        }
        if (orderID && place === "orders") {
          updateOrder(printFile, orderID, dispatch, userType, selected);
        } else {
          if (type === "graphic") {
            dispatch(setUploadedGraphicImage(printFile, "graphic"));
            dispatch(setPreviewImageSRC(mockupFile, "preview"));
          } else if (type === "notecard") {
            dispatch(setUploadedNotecardImage(printFile, "notecard"));
          }
        }
        dispatch(loadingAction(false));
        dispatch(setModalState(undefined));
      },
    });
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <iframe
        id="dm-iframe"
        src={`https://embed.dynamicmockups.com/mockup/${mockupUUID}`}
        style={{ width: "100%", height: "100%", border: "unset" }}
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
      ></iframe>
    </Box>
  );
};

export default DynamicMockupEditor;
