import dashboardIcon from "../../../assets/navbar_icons/dashboard.svg";
import storeIcon from "../../../assets/navbar_icons/Stores.svg";
import catalogIcon from "../../../assets/navbar_icons/Catalog.svg";
import productsIcon from "../../../assets/navbar_icons/Products.svg";
import ordersIcon from "../../../assets/navbar_icons/Orders.svg";
import trainingsIcon from "../../../assets/navbar_icons/Trainings.svg";
import tutorialsIcon from "../../../assets/navbar_icons/Tutorials.svg";
import lockIcon from "../../../assets/navbar_icons/ic_lock.svg";
import billingIcon from "../../../assets/navbar_icons/billing.svg";
import usersIcon from "../../../assets/navbar_icons/users.svg";

const icon = (name) => (
  <img src={name} alt="navbar-icons" style={{ width: 24, height: 24 }} />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: icon(dashboardIcon),
  },
  {
    title: "stores",
    path: "/dashboard/stores",
    icon: icon(storeIcon),
  },
  {
    title: "catalog",
    path: "/dashboard/catalog",
    icon: icon(catalogIcon),
  },
  {
    title: "products",
    path: "/dashboard/products",
    icon: icon(productsIcon),
  },
  {
    title: "orders",
    path: "/dashboard/orders",
    icon: icon(ordersIcon),
  },
  {
    title: "Trainings",
    path: "/dashboard/trainings",
    icon: icon(trainingsIcon),
  },
  {
    title: "Tutorials",
    path: "/dashboard/tutorials",
    icon: icon(tutorialsIcon),
  },
  {
    title: "users",
    path: "/dashboard/user",
    icon: icon(usersIcon),
  },
  {
    title: "Scopes",
    path: "/dashboard/scopes",
    icon: icon(lockIcon),
  },
  {
    title: "Apps",
    path: "/dashboard/apps",
    icon: icon(lockIcon),
  },
  {
    title: "ShipStation Credentials",
    path: "/dashboard/shipstation",
    icon: icon(lockIcon),
  },
  {
    title: "Subscription Management",
    path: "/dashboard/subscription-management",
    icon: icon(billingIcon),
  },
];

export default navConfig;
