import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  CardContent,
  Avatar,
  Card,
  Button,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowBack, ArrowForward, Star, Close } from "@mui/icons-material";
import Iconify from "src/components/iconify";
import { useDispatch } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { testimonials } from "../utils";

const styles = {
  width: 50,
  height: 50,
  backgroundColor: "primary.main",
  color: "#fff",
  borderRadius: 2,
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 10,
  "&:hover": { backgroundColor: "#0056b3" },
};

const UserStories = ({ title }) => {
  const dispatch = useDispatch();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleOpen = (item) => {
    if (isMobile) {
      setSelectedItem(item);
      setDrawerOpen(true);
    } else {
      dispatch(setModalState(<UserCard item={item} expanded />));
    }
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const UserCard = ({ item, expanded }) => (
    <Card
      sx={{
        boxShadow: 2,
        borderRadius: 2,
        border: "1px solid #E0E0E0",
        maxWidth: expanded ? "100%" : 400,
        margin: "auto",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Avatar
            src={item.image || ""}
            sx={{ width: 48, height: 48, bgcolor: "#E3F2FD" }}
          >
            {!item.image && "👤"}
          </Avatar>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {item.name}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              <Iconify icon="mdi:tick-circle" color="primary.main" />
              <Typography variant="caption" sx={{ color: "gray" }}>
                {item.role}
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 0.5, mt: 1 }}>
          {Array.from({ length: item.rating }).map((_, i) => (
            <Star key={i} sx={{ color: "#FFD700" }} />
          ))}
        </Box>

        <Typography
          variant="body2"
          sx={{
            mt: 2,
            color: "gray",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: expanded ? "unset" : 4,
          }}
          dangerouslySetInnerHTML={{
            __html: `"${item.review.replace(/\n\n/g, "<br /><br />")}"`,
          }}
        />

        {!expanded && (
          <Button
            size="small"
            onClick={() => handleOpen(item)}
            sx={{ textTransform: "none", mt: 1 }}
          >
            See More
          </Button>
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ my: { xs: 4, md: 0 } }}>
      <Stack alignItems="center" mb={4}>
        <Box
          textAlign={{ xs: "left", md: "center" }}
          sx={{ width: { xs: "90%", sm: "75%", md: "80%" } }}
        >
          {title ? (
            title
          ) : (
            <Typography variant="h2" fontWeight="bold">
              Unheard of User Results
            </Typography>
          )}
          <Typography variant="body1" mt={1}>
            Inner Circle Prints’ user results far exceed those of any other POD
            provider, thanks to our unique focus. The <strong>AVERAGE</strong>{" "}
            active Inner Circle Prints user generated just over{" "}
            <strong>1,000 sales</strong> in 2024, while our top users achieved
            between <strong>10,000 and 30,000 sales.</strong> This is the power
            of strategic product types and superior POD education!
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ position: "relative", width: "100%" }}>
        <IconButton ref={prevRef} sx={{ ...styles, left: 0 }}>
          <ArrowBack />
        </IconButton>

        <IconButton ref={nextRef} sx={{ ...styles, right: 0 }}>
          <ArrowForward />
        </IconButton>

        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={20}
          loop={true}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          breakpoints={{
            640: { slidesPerView: 1 },
            1024: { slidesPerView: 2 },
            1280: { slidesPerView: 3 },
            1536: { slidesPerView: 4 },
          }}
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          {testimonials?.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <UserCard item={testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {/* Drawer for Mobile */}
      {isMobile && (
        <Drawer
          anchor="bottom"
          open={drawerOpen}
          onClose={handleClose}
          PaperProps={{
            sx: {
              borderRadius: "20px 20px 0 0",
              height: "90vh",
              maxHeight: "90vh",
              overflowY: "auto",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Box sx={{ overflowY: "auto", maxHeight: "80vh", paddingX: 2 }}>
            {selectedItem && <UserCard item={selectedItem} expanded />}
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default UserStories;
