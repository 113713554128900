import React from "react";
import { useDispatch, useSelector } from "react-redux";
import StyledButton from "src/components/button/StyledButton";
import Iconify from "src/components/iconify";
import { setModalState } from "src/redux/actions/modalActions";
import LinkAmazonProduct from "src/sections/modals/products/LinkAmazonProduct";
import { unLinkProduct } from "../utils";
import { Stack } from "@mui/material";

const LinkToAmazon = () => {
  const dispatch = useDispatch();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const amazonSKU = catalogDetails?.amazon_sku;

  const linkAmazonProduct = () => {
    if (amazonSKU === null) {
      dispatch(
        setModalState(
          <LinkAmazonProduct
            id={catalogDetails?.id}
            page={1}
            rowsPerPage={25}
          />
        )
      );
    } else {
      unLinkProduct(dispatch, catalogDetails?.id, "amazon", 1, 25);
    }
  };

  return (
    <Stack direction={"row"} spacing={3} justifyContent={"flex-end"}>
      <StyledButton
        title={amazonSKU === null ? "Link To Amazon" : "Unlink Amazon product"}
        sx={{
          bgcolor: "#343b45",
          "&:hover": {
            bgcolor: "#343b45",
          },
        }}
        startIcon={<Iconify icon="bi:amazon" />}
        onClick={linkAmazonProduct}
      />
    </Stack>
  );
};

export default LinkToAmazon;
