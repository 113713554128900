import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { setModalState } from "src/redux/actions/modalActions";
import {
  loadingAction,
  loadingBtn2Action,
  loadingBtnAction,
  setSubscription,
} from "src/redux/actions/userActions";
import { setCoupons, setPlans } from "src/redux/actions/paymentActions";

export const getPlans = async (dispatch) => {
  dispatch(loadingAction(true));
  try {
    const res = await request.get("/subscriptions");
    if (res) {
      dispatch(setPlans(res.data.data));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    dispatch(loadingAction(false));
  }
};

export const addPlan = async (dispatch, payload) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/subscriptions", payload);
    if (res) {
      toastify("success", res.data.message);
      dispatch(loadingBtnAction(false));
      dispatch(setModalState(undefined));
      getPlans(dispatch);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(setModalState(undefined));
    dispatch(loadingBtnAction(false));
  }
};

export const deletePlan = async (dispatch, id) => {
  dispatch(loadingBtnAction(true));

  try {
    const res = await request.delete(`/subscriptions/${id}`);
    if (res) {
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      dispatch(setModalState(undefined));
      getPlans(dispatch);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const createSubscription = async (dispatch, payload, navigate) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/subscriptions/subscribe", payload);
    if (res) {
      toastify("success", res.data.message);
      dispatch(loadingBtnAction(false));
      dispatch(setModalState(undefined));
      if (navigate) {
        navigate("/login");
        localStorage.setItem("registered", false);
      } else {
        dispatch(setSubscription(res.data.data));
      }
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(setModalState(undefined));
    dispatch(loadingBtnAction(false));
  }
};

export const cancelPlan = async (dispatch) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.get("/subscriptions/unsubscribe/");
    if (res) {
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      dispatch(setModalState(undefined));
      dispatch(setSubscription(res.data.data));
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
  }
};

export const endTrial = async (dispatch, payload) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/subscriptions/end/trial", payload);
    if (res) {
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      getPlans(dispatch);
      dispatch(setSubscription(res.data.data));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const getCoupons = async (dispatch) => {
  dispatch(loadingAction(true));
  try {
    const res = await request.get("/subscriptions/promo-code/");
    if (res) {
      dispatch(setCoupons(res.data.data));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    dispatch(loadingAction(false));
  }
};

export const addCoupon = async (dispatch, payload) => {
  dispatch(loadingBtn2Action(true));
  try {
    const res = await request.post("/subscriptions/promo-code", payload);
    if (res) {
      toastify("success", res.data.message);
      dispatch(loadingBtn2Action(false));
      getCoupons(dispatch);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtn2Action(false));
  }
};

export const deleteCoupon = async (dispatch, code) => {
  dispatch(loadingBtnAction(true));

  try {
    const res = await request.delete(`/subscriptions/promo-code/${code}`);
    if (res) {
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      getCoupons(dispatch);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const verifyCoupon = async (
  dispatch,
  payload,
  setDiscountedAmount,
  setPromoError,
  setIsPromoValid
) => {
  dispatch(loadingBtn2Action(true));
  try {
    const res = await request.post(
      "/subscriptions/promo-code/validity",
      payload
    );
    if (res) {
      dispatch(loadingBtn2Action(false));
      toastify("success", "Coupon Verified");
      setDiscountedAmount(res.data.data);
      setPromoError("");
      setIsPromoValid(true);
    }
  } catch (e) {
    setPromoError("Invalid promo code");
    setIsPromoValid(false);
    dispatch(loadingBtn2Action(false));
  }
};
