import React from "react";
import { Typography } from "@mui/material";
import { getImageDimension } from "src/sections/modals/order/dimensions";
import { getCatalogDimensions } from "src/utils/getDimensions";

const Validations = ({ item }) => {
  let graphicDimensions = getCatalogDimensions(item?.catalog, "graphic", item);
  let notecardDimensions = getCatalogDimensions(
    item?.catalog,
    "notecard",
    item
  );

  const graphicImageDimensions = item.graphic_image
    ? getImageDimension(item?.graphic_image)
    : "";

  const notecardImageDimension = item?.notecard_image
    ? getImageDimension(item?.notecard_image)
    : "";

  let unit = [50, 51, 54].includes(item?.catalog?.id) ? "inch" : "px";

  return (
    <div>
      {" "}
      {graphicDimensions ? (
        <Typography variant="body2">
          <b>Standard Graphic Dimensions:</b>
          <br />
          {graphicDimensions?.width === null
            ? 0
            : [50, 51, 54].includes(item?.catalog?.id)
            ? graphicDimensions?.width / 300
            : graphicDimensions?.width}
          {unit} x{" "}
          {graphicDimensions?.height === null
            ? 0
            : [50, 51, 54].includes(item?.catalog?.id)
            ? graphicDimensions?.height / 300
            : graphicDimensions?.height}
          {unit}.
        </Typography>
      ) : (
        <Typography variant="body2">
          Please Select a Variation Before Uploading Your Graphic
        </Typography>
      )}
      {graphicImageDimensions?.width != null &&
        graphicImageDimensions?.height != null && (
          <>
            <Typography variant="body2" mt={1}>
              <b>Uploaded Graphic Dimensions:</b> <br />
              {`${graphicImageDimensions.width}px x ${graphicImageDimensions.height}px`}
            </Typography>
          </>
        )}
      {item?.notecard_image && (
        <>
          <Typography variant="body2">
            <b>Standard Notecard Dimensions:</b>
            <br />
            {notecardDimensions?.width === null ? 0 : notecardDimensions?.width}
            {unit} x{" "}
            {notecardDimensions?.height === null
              ? 0
              : notecardDimensions?.height}
            {unit}.
          </Typography>

          {notecardImageDimension?.width != null &&
            notecardImageDimension?.height != null && (
              <>
                <Typography variant="body2" mt={1}>
                  <b>Uploaded Notecard Dimensions:</b> <br />{" "}
                  {notecardImageDimension?.width === null ||
                  notecardImageDimension?.height === null
                    ? "NA"
                    : `${notecardImageDimension?.width}px x ${notecardImageDimension?.height}px`}
                </Typography>
              </>
            )}
        </>
      )}
    </div>
  );
};

export default Validations;
