import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Button, Typography, ListItem } from "@mui/material";
import SubscriptionCard from "src/components/subscription-card/SubscriptionCard";
import {
  createSubscription,
  endTrial,
} from "src/pages/subscription-management/requests";
import { setModalState } from "src/redux/actions/modalActions";
import CancelSubscription from "src/sections/modals/subscription/CancelSubscription";

const SubscriptionPlan = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loadingButton);
  const subscription = useSelector((state) => state.user.subscription);

  const cancelPlanPayload = {
    plan_id: subscription?.plan?.id,
  };

  const endTrialPayload = {
    subscription_id: subscription?.subscription_id,
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          {subscription !== null ? (
            <SubscriptionCard
              item={subscription?.plan}
              status={subscription?.status}
              onCancel={() => dispatch(setModalState(<CancelSubscription />))}
              resubscribe={() =>
                createSubscription(dispatch, cancelPlanPayload)
              }
            />
          ) : (
            <SubscriptionCard
              item={{
                name: "Free Plan",
                description:
                  "This is Free plan for ICP sellers. Can be subscribed to paid plan for premium features",
              }}
            />
          )}
        </Grid>
        {subscription?.is_trial === "yes" && (
          <Grid item xs={12} sm={6} md={4}>
            <ListItem
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Box
                sx={{
                  p: 3,
                  borderRadius: 2,
                  boxShadow: 3,
                  bgcolor: "background.paper",
                  textAlign: "center",
                  maxWidth: 400,
                  width: "100%",
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  End Free Trial
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1, mb: 2 }}
                >
                  Your free trial is about to expire. Click below to end it now.
                </Typography>
                <Button
                  variant="contained"
                  color="inherit"
                  fullWidth
                  onClick={() => endTrial(dispatch, endTrialPayload)}
                >
                  {loading ? "Loading..." : "End Trial"}
                </Button>
              </Box>
            </ListItem>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default SubscriptionPlan;
