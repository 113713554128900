import React from "react";
import { Box, Typography, CardMedia } from "@mui/material";
import { NavLink } from "react-router-dom";

const Banner = () => {
  const videoUrl = "https://player.vimeo.com/video/1058036636?h=d9535a242c";

  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: "auto",
        textAlign: "left",
        p: 2,
        pl: 4,
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 1,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontWeight: "bold",
          color: "primary.main",
          lineHeight: 0.8,
          fontFamily: '"League Spartan", sans-serif',
        }}
      >
        Ready to take <br /> your business to <br /> the next level?
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
        }}
      >
        APPLY FOR 1-ON-1 COACHING FROM A 7-FIGURE ICP SELLER
      </Typography>

      <CardMedia
        component="iframe"
        src={`${videoUrl}&autoplay=1&muted=1&loop=1`}
        height="300"
        sx={{ width: 500 }}
        frameborder="0"
        allowFullScreen
      />

      <NavLink
        to="/apply"
        sx={{
          display: "block",
          fontSize: "0.9rem",
          color: "#009CFF",
          textDecoration: "underline",
        }}
      >
        Check out our testimonials, meet our coaches, and apply now
      </NavLink>
    </Box>
  );
};

export default Banner;
