import React, { useEffect, useState } from "react";
import request from "src/utils/request";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import DailyBarChart from "./DailyBarChart";
import Iconify from "src/components/iconify";
import YearlyBarChart from "./YearlyBarChart";
import CustomDatePicker from "./CustomDatepicker";
import { formatStats } from "../utils";
import { useDispatch } from "react-redux";
import { formatDateByPattern } from "src/utils/formatDate";
import { loadingAction } from "src/redux/actions/userActions";

const DailyStats = () => {
  const dispatch = useDispatch();
  const [stats, setStats] = useState([]);
  const [type, setType] = useState("date");
  const [chartData, setChartData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    getDailyStats();
  }, []);

  useEffect(() => {
    const data = formatStats(stats, type);
    setChartData(data);
  }, [stats]);

  const getDailyStats = async () => {
    dispatch(loadingAction(true));
    const payload = {
      date:
        type === "year"
          ? formatDateByPattern(new Date(selectedDate), "YYYY")
          : type === "month"
          ? formatDateByPattern(new Date(selectedDate), "YYYY-MM")
          : type === "date"
          ? formatDateByPattern(new Date(selectedDate), "YYYY-MM-DD")
          : formatDateByPattern(new Date(), "YYYY-MM-DD"),
    };
    try {
      const res = await request.post(`/users/stats/datewise`, payload);

      if (res) {
        dispatch(loadingAction(false));
        setStats(res.data.data);
      }
    } catch (e) {
      if (e.response.status === 404) {
        setStats([]);
      }

      dispatch(loadingAction(false));
    }
  };

  const handleChange = (event) => {
    const newType = event.target.value;
    setType(newType);
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          borderRadius: "15px",
          my: "40px",
          p: 4,
        }}
        maxWidth={false}
      >
        <Typography variant="h4">Sales Revenue Overview</Typography>
        <Stack
          direction={"row"}
          my={2}
          spacing={4}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} spacing={4}>
            <Box display="flex" alignItems="center">
              <Box sx={{ bgcolor: "#C4C2EB", p: 1, borderRadius: "20%" }}>
                <Iconify icon="ph:chart-line" color="#605bbb" />
              </Box>
              <Stack direction={"column"} ml={2}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  Admin Revenue Today
                </Typography>
                <Typography variant="h5" component="div">
                  ${stats?.current_day_revenue}
                </Typography>
              </Stack>
            </Box>

            <Box display="flex" alignItems="center">
              <Box
                sx={{ bgcolor: "primary.lighter", p: 1, borderRadius: "20%" }}
              >
                <Iconify icon="solar:box-outline" color="primary.main" />
              </Box>
              <Stack direction={"column"} ml={2}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  Products Launched Today
                </Typography>
                <Typography variant="h5" component="div">
                  {stats?.current_day_products_launched}
                </Typography>
              </Stack>
            </Box>
          </Stack>
          <Stack direction={"row"} spacing={4} alignItems={"center"}>
            <FormControl fullWidth>
              <InputLabel id="date-type-label">Type</InputLabel>
              <Select
                labelId="date-type-label"
                id="date-type-id"
                value={type}
                label="Type"
                onChange={handleChange}
              >
                <MenuItem value={"date"}>date</MenuItem>
                <MenuItem value={"month"}>month</MenuItem>
                <MenuItem value={"year"}>year</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <CustomDatePicker
                type={type}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </FormControl>
            <Box>
              <Button
                variant="contained"
                size="small"
                onClick={() => getDailyStats()}
              >
                Filter
              </Button>
            </Box>
          </Stack>
        </Stack>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            my: 3,
          }}
        >
          {type === "year" ? (
            <YearlyBarChart chartData={chartData} />
          ) : (
            <DailyBarChart chartData={chartData} />
          )}
        </Box>
      </Paper>
    </>
  );
};

export default DailyStats;
