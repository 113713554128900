export const setTrainingsData = (content) => async (dispatch) => {
  dispatch({
    type: "setTrainingsData",
    payload: content,
  });
};

export const setTrainingSections = (sections) => async (dispatch) => {
  dispatch({
    type: "setTrainingSections",
    payload: sections,
  });
};

export const setTrainingsDetail = (detail) => async (dispatch) => {
  dispatch({
    type: "setTrainingsDetail",
    payload: detail,
  });
};

export const setTutorialsData = (content) => async (dispatch) => {
  dispatch({
    type: "setTutorialsData",
    payload: content,
  });
};

export const setTutorialsDetail = (detail) => async (dispatch) => {
  dispatch({
    type: "setTutorialsDetail",
    payload: detail,
  });
};

export const setUpdates = (data) => async (dispatch) => {
  dispatch({
    type: "setUpdates",
    payload: data,
  });
};
