import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Drawer, Link, Stack } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import navConfig from "./config";
import { useSelector } from "react-redux";
import Iconify from "src/components/iconify/Iconify";
import CountdownTimer from "src/components/timer/CountdownTimer";

const NAV_WIDTH = 280;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const userType = useSelector((state) => state.user.user.type);

  const NavRoutes =
    userType === "Seller"
      ? navConfig.filter(
          (_, index) =>
            index === 0 ||
            index === 1 ||
            index === 2 ||
            index === 3 ||
            index === 4 ||
            index === 5 ||
            index === 6
        )
      : userType === "Developer"
      ? navConfig.filter(
          (_, index) => index === 0 || index === 8 || index === 9
        )
      : userType === "AdminAccessGuard"
      ? navConfig.filter((_, index) => index === 7)
      : userType === "PrintProvider"
      ? navConfig.filter((_, index) => index === 4 || index === 10)
      : navConfig.filter(
          (_, index) =>
            index === 0 ||
            index === 2 ||
            index === 4 ||
            index === 5 ||
            index === 6 ||
            index === 7 ||
            index === 8 ||
            index === 11
        );

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const StyledContainer = styled("div")({
    position: "relative",
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 996,
  });

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <NavSection data={NavRoutes} />
      {userType === "Seller" && (
        <StyledContainer>
          <Link
            href="https://app.livestorm.co/p/d365d1ea-eb3d-4a62-be45-e293a8cbd8db?s=5e3bcfb7-c778-4677-b9b8-6b182b9d93e1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Iconify icon="mage:broadcast-fill" width={24} />
              <span>Live Coaching Session</span>
            </Stack>
          </Link>
          <CountdownTimer targetDate="2025-03-27T14:30:00-08:00" />
        </StyledContainer>
      )}
    </Scrollbar>
  );

  return (
    <Box
      className="testing-wrapper"
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          className="Drawer-test-1"
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "white",
              margin: "10px",
              borderRadius: 3,
              height: "70vh",
              borderRightStyle: "dashed",
              position: "relative",
              zIndex: 0,
              boxShadow: "5px -1px 5px 0px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          className="Drawer-test-2"
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
