import React, { useState } from "react";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const ShippingCost = ({catalogDetails}) => {
  const [openIndex, setOpenIndex] = useState(null);

  const initialPrice = catalogDetails?.shipping_price
    ? parseFloat(catalogDetails?.shipping_price)
    : 0;
  const additionalShipPrice = catalogDetails?.additional_shipping_price
    ? parseFloat(catalogDetails?.additional_shipping_price)
    : 0;

  const catalog = {
    catalogID: catalogDetails?.id,
    name: catalogDetails?.title,
    shipping_detail: [
      {
        method: "Standard",
        time: "2 - 5 business days",
        firstItem: initialPrice,
        additionalItem: additionalShipPrice,
        per_quantity: [
          {
            quantity: "Less than 3",
            price: "$5.83",
          },
          {
            quantity: "Equal to 3",
            price: "$4.83",
          },
          {
            quantity: "Greater than 3",
            price: "$3.83",
          },
        ],
      },
      {
        method: "Priority",
        time: "2 - 5 business days",
        firstItem: parseFloat(initialPrice + 5.99).toFixed(2),
        additionalItem: parseFloat(additionalShipPrice + 2.5).toFixed(2),
        per_quantity: [
          {
            quantity: "Less than 3",
            price: "$5.83 + $5.99 + $2.50",
          },
          {
            quantity: "Equal to 3",
            price: "$4.83 + $5.99 + $2.50 + $2.50",
          },
          {
            quantity: "Greater than 3",
            price: "$3.83 + $5.99 + ($2.50 x quantity)",
          },
        ],
      },
    ],
  };

  const handleExpandClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Box sx={{ width: "fit-content", p: 0, m: 0 }}>
      <TableContainer component={Paper} sx={{ my: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {catalogDetails?.id === 5 && <TableCell />}{" "}
              {/* Expand Icon Column */}
              <TableCell>Shipping method</TableCell>
              <TableCell>Delivery time</TableCell>
              <TableCell>First item</TableCell>
              <TableCell>Additional item</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {catalog?.shipping_detail?.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  {catalogDetails?.id === 5 && (
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleExpandClick(index)}
                      >
                        {openIndex === index ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                  )}
                  <TableCell>{row.method}</TableCell>
                  <TableCell>{row.time}</TableCell>
                  <TableCell>${row.firstItem}</TableCell>
                  <TableCell>${row.additionalItem}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse
                      in={openIndex === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          Per Quantity
                        </Typography>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>

                              <TableCell>Quantity</TableCell>
                              <TableCell>Per Unit</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row?.per_quantity?.map((item, Qindex) => (
                              <TableRow key={Qindex}>
                                <TableCell component="th" scope="row">
                                  {Qindex + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {item.quantity}
                                </TableCell>
                                <TableCell>{item.price}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ShippingCost;
