function gcd(a, b) {
  return b === 0 ? a : gcd(b, a % b);
}

function calculateAspectRatio(width, height) {
  const divisor = gcd(width, height);
  return `${width / divisor}:${height / divisor}`;
}

export function checkDimensionsById(item, width, height) {
  const inputAspectRatio = calculateAspectRatio(width, height);
  const itemAspectRatio = calculateAspectRatio(item.width, item.height);
  const meetsMinDimensions = width >= item.width && height >= item.height;

  return (
    meetsMinDimensions &&
    ((width === height && item.width === item.height) ||
      inputAspectRatio === itemAspectRatio)
  );
}

export const getImageDimension = (link) => {
  if (Array.isArray(link)) {
    return link[1]?.metadata ? link[1]?.metadata : "";
  } else {
    return link?.metadata ? link?.metadata : "";
  }
};
