import React from "react";
import AddURL from "./components/AddURL";
import UploadFile from "./components/UploadFile";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { LightTooltip, StyledPaperContainer } from "./ProductDetails.styles";
import { capitalizeFirstLetter } from "src/utils/commonFunctions";
import { getCatalogDimensions } from "src/utils/getDimensions";
import AddPreviewImage from "./AddPreviewImage";
import helloCustomIcon from "../../../assets/helloCustom.png";

const AddGraphicImage = ({
  uploadedFile,
  deleteFile,
  type,
  urlValue,
  onURLChange,
  urlError,
  disableURL,
  hellocustomChecked,
  handleCheckboxChange,
  catalogDetails,
  location,
  orderID,
}) => {
  let graphicDimensions = getCatalogDimensions(catalogDetails, "graphic", {});
  let notecardDimensions = getCatalogDimensions(catalogDetails, "notecard", {});

  const catalogItem =
    type === "graphic" ? graphicDimensions : notecardDimensions;
  const disableUpload = hellocustomChecked ? true : !!urlValue;

  const dimensionsTitle =
    type === "graphic"
      ? catalogDetails?.no_of_graphics === 1
        ? "Graphic"
        : catalogDetails?.no_of_graphics === 2
        ? "Pendant"
        : ""
      : "Notecard";

  return (
    <Box>
      <Typography variant="h4">
        {capitalizeFirstLetter(dimensionsTitle)} Design
      </Typography>
      {[50, 51, 52, 54].includes(catalogDetails?.id) ? (
        <></>
      ) : (
        <Typography variant="body2" sx={{ my: 2 }}>
          <b>Standard {capitalizeFirstLetter(dimensionsTitle)} Dimensions:</b>{" "}
          {catalogItem?.width === null ? 0 : catalogItem?.width}
          px x {catalogItem?.height === null ? 0 : catalogItem?.height}
          px.
        </Typography>
      )}

      <Stack
        direction={{ xs: "column", sm: "column" }}
        justifyContent={"center"}
        flexWrap={"wrap"}
        spacing={4}
        sx={{
          width: "100%",
          my: 2,
        }}
      >
        <Box>
          <LightTooltip
            title={"For Non Personalized Graphics"}
            placement="bottom-end"
          >
            <StyledPaperContainer>
              <UploadFile
                uploadedFile={uploadedFile}
                disableUpload={disableUpload}
                deleteFile={deleteFile}
                catalogDetails={catalogDetails}
                type={type}
              />
            </StyledPaperContainer>
          </LightTooltip>

          {!orderID && type === "graphic" && location === "Catalog" && (
            <AddPreviewImage />
          )}
        </Box>

        <Box sx={{ flex: 1 }}>
          <Stack direction="row" alignItems={"center"} spacing={3} mb={1}>
            <Typography variant="h5" sx={{ mb: 1 }}>
              Link Your Design
            </Typography>
            <Stack direction="row" alignItems={"center"}>
              <img
                src={helloCustomIcon}
                alt="hellocustom"
                width={30}
                height={30}
              />
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                checked={hellocustomChecked}
                onChange={handleCheckboxChange}
              />
            </Stack>
          </Stack>

          <LightTooltip
            title={"For Personalized Graphics"}
            placement="bottom-end"
          >
            <AddURL
              urlValue={urlValue}
              onChange={onURLChange}
              urlError={urlError}
              disabled={disableURL}
              location={location}
              hellocustomChecked={hellocustomChecked}
              handleCheckboxChange={handleCheckboxChange}
            />
          </LightTooltip>
        </Box>
      </Stack>
    </Box>
  );
};

export default AddGraphicImage;
