import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Iconify from "src/components/iconify";
import { Link } from "@mui/material";
import { useSelector } from "react-redux";

function ProductLinks() {
  const [selectedCard, setSelectedCard] = React.useState(0);
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);

  const cards = [
    {
      id: 1,
      title: "Product Mock-ups",
      description: "Access Product Mock-ups Here.",
      link: catalogDetails?.mockup_link,
    },
    {
      id: 2,
      title: "Design Templates",
      description: "Access Design Templates Here.",
      link: catalogDetails?.design_link
        ? catalogDetails?.design_link[0]?.link
        : "",
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(min(200px, 100%), 1fr))",
        gap: 2,
      }}
    >
      {cards.map((card, index) => (
        <Card>
          <Link href={card?.link} target="_blank" rel="noopener noreferrer">
            <CardActionArea
              onClick={() => setSelectedCard(index)}
              data-active={selectedCard === index ? "" : undefined}
              sx={{
                height: "100%",
                backgroundColor: "#bde6f8",
                color: "#0AA4E6",
              }}
            >
              <CardContent sx={{ height: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography variant="h5" component="div">
                    {card.title}
                  </Typography>
                  <Iconify icon="icon-park-outline:share" />
                </Box>
                <Typography variant="body2">{card.description}</Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      ))}
    </Box>
  );
}

export default ProductLinks;
