import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  Typography,
  Paper,
  Stack,
  Chip,
  Divider,
  InputAdornment,
  FormControlLabel,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  createSubscription,
  getPlans,
  verifyCoupon,
} from "src/pages/subscription-management/requests";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const currentYear = new Date().getFullYear() % 100;

const schema = yup.object().shape({
  plan: yup.string().required("Plan is required"),
  cardNumber: yup
    .string()
    .test("is-valid-card", "Invalid card number", (value) =>
      value ? value.replace(/\s/g, "").match(/^\d{16}$/) : false
    )
    .required("Card number is required"),
  expiryDate: yup
    .string()
    .matches(/^(0[1-9]|1[0-2])\/(\d{2})$/, "Invalid expiry format MM/YY")
    .test("valid-year", "Year must not be in the past", (value) => {
      if (!value) return false;
      const [, year] = value.split("/");
      return parseInt(year, 10) >= currentYear;
    })
    .required("Expiry date is required"),
  cvc: yup
    .string()
    .matches(/^\d{3,4}$/, "Invalid CVC")
    .max(4, "CVC must be at most 4 digits")
    .required("CVC is required"),
  promoCode: yup.string().nullable(),
});

const formatCardNumber = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim();
};

const formatExpiryDate = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d{0,2})$/, "$1/$2")
    .trim();
};

const SubscriptionForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscriptionPlans = useSelector(
    (state) => state.payment.subscriptionPlans
  );
  const isLoading = useSelector((state) => state.user.loadingButton);
  const isVerifying = useSelector((state) => state.user.loadingButton2);

  const [promoError, setPromoError] = useState("");
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [appliedDiscounts, setAppliedDiscounts] = useState({});

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      plan: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
      promoCode: "",
    },
  });

  useEffect(() => {
    if (subscriptionPlans.length > 0) {
      setValue("plan", subscriptionPlans[0].id);
    }
  }, [subscriptionPlans, setValue]);

  useEffect(() => {
    getPlans(dispatch);
  }, [dispatch]);

  const verifyPromoCode = async () => {
    const promoCode = getValues("promoCode");
  
    if (!promoCode) {
      setPromoError("Promo code cannot be empty");
      setIsPromoValid(false);
      return;
    } else {
      setPromoError("");
      
      verifyCoupon(
        dispatch,
        { promo_code: promoCode },
        (discountAmount) => {
          // Apply the discount to all plans
          setAppliedDiscounts(
            subscriptionPlans.reduce((acc, item) => {
              acc[item.id] = discountAmount;
              return acc;
            }, {})
          );
          setIsPromoValid(true);
        },
        setPromoError,
        setIsPromoValid
      );
    }
  };
  

  const onSubmit = (data) => {
    const month = data?.expiryDate?.split("/")[0];
    const year = data?.expiryDate?.split("/")[1];

    const payload = {
      plan_id: parseInt(data?.plan),
      card_number: data.cardNumber.replace(/\s/g, ""),
      card_expiry_month: month,
      card_expiry_year: year,
      card_cvc: data?.cvc,
    };

    if (isPromoValid) {
      payload.promo_code = data.promoCode;
    }

    createSubscription(dispatch, payload, navigate);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography
            component="span"
            sx={{ textAlign: "left", mb: 1, color: "#000" }}
          >
            Plans
          </Typography>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              borderRadius: 2,
              borderColor: "#cdd6de",
            }}
          >
            <Controller
              name="plan"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction={"column"} spacing={1}>
                    {subscriptionPlans?.map((item, index) => {
                      const discountPercentage = appliedDiscounts[item.id] || 0;
                      const discountedPrice =
                        item.price - (item.price * discountPercentage) / 100;

                      return (
                        <React.Fragment key={index}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            <FormControlLabel
                              value={item.id}
                              control={<Radio />}
                              label={
                                <Box textAlign={"left"} fontWeight={500}>
                                  {item.name} (${discountedPrice.toFixed(2)}/
                                  {item.interval}){" "}
                                  {discountPercentage > 0 && (
                                    <Chip
                                      label={`-${discountPercentage}% OFF`}
                                      size="small"
                                      sx={{
                                        ml: 1,
                                        backgroundColor: "#FFEBEE",
                                        color: "#D32F2F",
                                      }}
                                    />
                                  )}
                                  {item.interval === "year" && (
                                    <Chip
                                      label="SAVE 35%"
                                      size="small"
                                      sx={{
                                        ml: 1,
                                        backgroundColor: "#1EDC7D1A",
                                        color: "#1EDC7D",
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                              sx={{ flex: 1 }}
                            />
                          </Box>
                          {index !== subscriptionPlans?.length - 1 && (
                            <Divider
                              orientation="horizontal"
                              sx={{ color: "#1EDC7D" }}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Stack>
                </RadioGroup>
              )}
            />
          </Paper>
        </FormControl>

        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography
            component="span"
            sx={{ textAlign: "left", color: "#000", mb: 2 }}
          >
            Promo code
          </Typography>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <Controller
              name="promoCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter promo code"
                  fullWidth
                  size="small"
                  error={!!promoError}
                  helperText={promoError}
                />
              )}
            />
            <Button
              variant="contained"
              color="inherit"
              onClick={verifyPromoCode}
              disabled={isVerifying}
            >
              {isVerifying ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Verify"
              )}
            </Button>
          </Stack>
        </FormControl>

        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography
            component="span"
            sx={{ textAlign: "left", color: "#000" }}
          >
            Card Info
          </Typography>

          <Controller
            name="cardNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="1234 1234 1234 1234"
                fullWidth
                margin="normal"
                error={!!errors.cardNumber}
                helperText={errors.cardNumber?.message}
                onChange={(e) =>
                  field.onChange(formatCardNumber(e.target.value))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">💳</InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Box display="flex" gap={2} mt={1}>
            <Controller
              name="expiryDate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="MM / YY"
                  fullWidth
                  error={!!errors.expiryDate}
                  helperText={errors.expiryDate?.message}
                  onChange={(e) =>
                    field.onChange(formatExpiryDate(e.target.value))
                  }
                />
              )}
            />
            <Controller
              name="cvc"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="CVC"
                  fullWidth
                  type="number"
                  inputProps={{ maxLength: 4 }}
                  error={!!errors.cvc}
                  helperText={errors.cvc?.message}
                />
              )}
            />
          </Box>
        </FormControl>
      </Stack>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        sx={{ mt: 4 }}
      >
        {isLoading ? "Loading..." : "START FREE TRIAL"}
      </Button>

      <Typography
        align="center"
        sx={{ mt: 2, fontSize: "14px", fontWeight: 800 }}
      >
        Cancel anytime
      </Typography>
    </Box>
  );
};

export default SubscriptionForm;
