import React from "react";
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import catalog1 from "../../../assets/home/catalog/1.webp";
import catalog2 from "../../../assets/home/catalog/2.webp";
import catalog3 from "../../../assets/home/catalog/3.webp";
import catalog4 from "../../../assets/home/catalog/4.webp";
import catalog5 from "../../../assets/home/catalog/5.webp";
import catalog6 from "../../../assets/home/catalog/6.webp";
import catalog7 from "../../../assets/home/catalog/7.webp";
import catalog8 from "../../../assets/home/catalog/8.webp";
import catalog9 from "../../../assets/home/catalog/9.webp";
import catalog10 from "../../../assets/home/catalog/10.webp";
import catalog11 from "../../../assets/home/catalog/11.webp";
import catalog12 from "../../../assets/home/catalog/12.webp";
import catalog13 from "../../../assets/home/catalog/13.webp";

const slides = [
  { image: catalog1 },
  { image: catalog2 },
  { image: catalog3 },
  { image: catalog4 },
  { image: catalog5 },
  { image: catalog6 },
  { image: catalog7 },
  { image: catalog8 },
  { image: catalog9 },
  { image: catalog10 },
  { image: catalog11 },
  { image: catalog12 },
  { image: catalog13 },
];

const ImageSlider = () => {
  return (
    <Box sx={{ width: "100%", mx: "auto", py: 2 }}>
      <Swiper
        slidesPerView={1.5}
        spaceBetween={20}
        loop={true}
        speed={10000}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        freeMode={true}
        breakpoints={{
          640: { slidesPerView: 2 },
          1024: { slidesPerView: 4 },
        }}
        modules={[Autoplay]}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Box
              component="img"
              src={slide.image}
              alt={"catalogs"}
              sx={{ maxWidth: "100%", height: "auto" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ImageSlider;
