import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Pagination,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import AddFAQs from "src/sections/modals/catalog/addFAQs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQs = ({ catalogDetails }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const itemsPerPage = 8;
  const [page, setPage] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const userType = useSelector((state) => state.user.user.type);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setExpanded(false);
  };

  const paginatedFaqs = catalogDetails?.faqs?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <Box>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography component="div" variant="h5" mb={3}>
          Frequently Asked Questions
        </Typography>
        {userType === "Super Admin" && (
          <div>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                dispatch(
                  setModalState(<AddFAQs catalogID={catalogDetails?.id} />)
                )
              }
            >
              + Add
            </Button>
          </div>
        )}
      </Stack>
      {paginatedFaqs?.map((item, index) => (
        <Accordion
          key={index}
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            "&::before": {
              display: "none",
            },
          }}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography component="span" fontWeight={500}>
              {item?.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{item?.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Pagination
          count={Math.ceil(catalogDetails?.faqs?.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default FAQs;
