import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { loadingAction, loadingBtnAction } from "src/redux/actions/userActions";
import { setTutorialsData } from "src/redux/actions/educationActions";
import { setModalState } from "src/redux/actions/modalActions";

export const getTutorialsData = async (dispatch) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get("/tutorial");
    if (res) {
      dispatch(loadingAction(false));
      dispatch(setTutorialsData(res.data.data));
    }
  } catch (e) {
    dispatch(loadingAction(false));
    if (e.response.status === 404) {
      dispatch(setTutorialsData([]));
    } else {
      toastify("error", e.response.data.message);
    }
  }
};

export const addTutorialsContent = async (dispatch, formData) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/tutorial", formData);

    if (res) {
      dispatch(setModalState(undefined));
      getTutorialsData(dispatch);
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const updateTutorialsContent = async (dispatch, id, formData) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post(`/tutorial/${id}`, formData);

    if (res) {
      dispatch(setModalState(undefined));
      getTutorialsData(dispatch);
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const deleteTutorialsContent = async (dispatch, id) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.delete(`/tutorial/${id}`);

    if (res) {
      dispatch(setModalState(undefined));
      getTutorialsData(dispatch);
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};
