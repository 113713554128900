import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import ShopProductCard from "./ProductCard";
import { Grid, Button, Card, Typography, Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCartItems } from "src/pages/dashboard/requests";
import accesoriesImage from "../../../assets/accesories.png";
import resendsImage from "../../../assets/resends.png";
import MyImage from "src/components/lazy-load-image/MyImage";
import { ArrowBack } from "@mui/icons-material";

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({
  products,
  page,
  rowsPerPage,
  product,
  ...other
}) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const topRef = useRef(null);

  useEffect(() => {
    if (userType === "Seller") {
      getCartItems(dispatch);
    }
  }, []);

  // Categorize products based on specific IDs
  const accessories = products?.filter((product) =>
    [30, 31, 42, 43, 44, 45, 46, 53].includes(product?.id)
  );

  const resends = products.filter((product) => product.name.includes("Resend"));

  const mainProducts = products.filter(
    (product) => !accessories.includes(product) && !resends.includes(product)
  );

  // Get the products to display
  let displayProducts = [];
  if (selectedCategory === "Accessories") {
    displayProducts = accessories;
  } else if (selectedCategory === "Resends") {
    displayProducts = resends;
  } else {
    displayProducts = [...mainProducts];
  }

  // Handle category click
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setTimeout(() => {
      topRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  // Handle back button click
  const handleBackClick = () => {
    setSelectedCategory(null);
    setTimeout(() => {
      topRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  return (
    <div ref={topRef}>
      {selectedCategory ? (
        <>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleBackClick}
            sx={{ marginBottom: 2 }}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>

          <Grid container spacing={3} {...other}>
            {displayProducts.map((product) => (
              <Grid key={product.id} item xs={12} sm={12} md={6} lg={4} xl={3}>
                <ShopProductCard
                  product={product}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Grid container spacing={3} {...other}>
          {mainProducts.map((product) => (
            <Grid key={product.id} item xs={12} sm={12} md={6} lg={4} xl={3}>
              <ShopProductCard
                product={product}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card onClick={() => handleCategoryClick("Accessories")}>
              <Box sx={{ pt: "100%", position: "relative" }}>
                <MyImage
                  image={accesoriesImage}
                  sx={{
                    top: 0,
                    position: "absolute",
                    width: "100% ",
                    height: "100%",
                  }}
                />
              </Box>

              <Stack spacing={2} sx={{ p: 3, pb: 11 }}>
                <Typography variant="h6" noWrap>
                  Accesories
                </Typography>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card onClick={() => handleCategoryClick("Resends")}>
              <Box sx={{ pt: "100%", position: "relative" }}>
                <MyImage
                  image={resendsImage}
                  sx={{
                    top: 0,
                    position: "absolute",
                    width: "100% ",
                    height: "100%",
                  }}
                />
              </Box>

              <Stack spacing={2} sx={{ p: 3, pb: 11 }}>
                <Typography variant="h6" noWrap>
                  Resends
                </Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
