import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";

const CustomDatePicker = ({ type, selectedDate, setSelectedDate }) => {
  let views = [];
  let format = "";

  switch (type) {
    case "year":
      views = ["year"];
      format = "yyyy";
      break;
    case "month":
      views = ["month", "year"];
      format = "MMMM/yyyy";
      break;
    case "date":
    default:
      views = ["year", "month", "day"];
      format = "MM/dd/yyyy";
      break;
  }

  return (
    <DatePicker
      label={type?.charAt(0).toUpperCase() + type?.slice(1) + " Picker"}
      views={views}
      onChange={(newValue) => {
        setSelectedDate(newValue);
      }}
      value={selectedDate}
      inputFormat={format}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default CustomDatePicker;
