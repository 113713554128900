import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Stack,
  Divider,
} from "@mui/material";
import { NavLink } from "react-router-dom";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  terms: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions"),
});

const UserForm = ({ setStep, setUserData }) => {
  const defaultValues = JSON.parse(localStorage.getItem("userFormData")) || {
    firstName: "",
    lastName: "",
    email: "",
    terms: false,
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    const subscription = watch((data) => {
      localStorage.setItem("userFormData", JSON.stringify(data));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    setUserData(data);
    setStep("password");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="First Name"
              variant="outlined"
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Last Name"
              variant="outlined"
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Email"
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />

        <Controller
          name="terms"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value ?? false}
                  sx={{ color: "rgb(198, 197, 203)" }}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              }
              label={
                <Typography
                  variant="caption"
                  fontWeight={500}
                  color={"#75839e"}
                >
                  I agree to the{" "}
                  <NavLink
                    to="/privacypolicy"
                    color="primary"
                    style={{ textDecoration: "none" }}
                  >
                    Privacy Policy
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="/termsofservice"
                    color="primary"
                    style={{ textDecoration: "none" }}
                  >
                    Terms of Service
                  </NavLink>
                  .
                </Typography>
              }
            />
          )}
        />
        {errors.terms && (
          <Typography
            color="error"
            textAlign={"left"}
            marginTop={"0px !important"}
            variant="caption"
          >
            {errors.terms.message}
          </Typography>
        )}

        <Button fullWidth variant="contained" type="submit" size="large">
          SIGN UP
        </Button>
        <Divider sx={{ my: 2 }}>or</Divider>
        <NavLink to={"/login"}>
          <Button fullWidth size="large" type="button" variant="outlined">
            LOGIN
          </Button>
        </NavLink>
      </Stack>
    </form>
  );
};

export default UserForm;
