import request from "src/utils/request";
import { getAllOrdersList } from ".";
import { loadingBtnAction } from "src/redux/actions/userActions";
import { toastify } from "src/utils/toast";

export const duplicateOrder = async (dispatch, userType, orderID) => {
  dispatch(loadingBtnAction(true));

  const page =
    sessionStorage.getItem("page") !== null
      ? sessionStorage.getItem("page")
      : 1;

  const rowsPerPage =
    sessionStorage.getItem("rowsPerPage") !== null
      ? sessionStorage.getItem("rowsPerPage")
      : 25;

  const tabValue = sessionStorage.getItem("activeTabIndex")
    ? sessionStorage.getItem("activeTabIndex")
    : "1";

  const payload = {
    order_id: orderID,
  };

  try {
    const res = await request.post("/orders/duplicate", payload);

    if (res) {
      getAllOrdersList(dispatch, userType, page, rowsPerPage, tabValue);
      dispatch(loadingBtnAction(false));
      toastify("success", res.message);
    }
  } catch (e) {
    toastify("error", e.message);
    dispatch(loadingBtnAction(false));
  }
};
