import React, { useEffect, useState } from "react";
import AddGraphicImage from "./AddGraphicImage";
import { Box, Card, Stack, Tab, Typography } from "@mui/material";
import {
  setGraphicURL,
  setNotecardURL,
  setPreviewImageSRC,
  setUploadedGraphicImage,
  setUploadedNotecardImage,
} from "src/redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import {
  convertImageToFile,
  handleConvert,
  isImageUrl,
  isValidURL,
} from "./utils";
import { ImageContainer, StyledCardMedia } from "./ProductDetails.styles";
import AddToCart from "./components/AddToCart";
import LinkToEtsy from "./components/LinkToEtsy";
import LinkToAmazon from "./components/LinkToAmazon";
import UpdateProduct from "./components/UpdateProduct";
import AddToProducts from "./components/AddToProducts";
import EditCatalogTitle from "./components/EditCatalogTitle";
import ProductDescription from "./components/tabs/ProductDescription";
import { isEmptyObject } from "src/utils/commonFunctions";
import Carousel from "react-material-ui-carousel";
import ImageViewPopup from "src/components/image-viewer/ImageViewPopup";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ShippingCost from "./components/tabs/ShippingCost";
import FAQs from "./components/tabs/FAQs";
import { toastify } from "src/utils/toast";
import EditPreviewImage from "./components/EditPreviewImage";
import { getCartItems } from "src/pages/dashboard/requests";
import ProductLinks from "./components/ProductLinks";
import Iconify from "src/components/iconify";
import LinkButtons from "./components/LinkButtons";
import helloCustomImage from "../../../assets/helloCustomImage.png";
import SKUTable from "src/sections/modals/products/SKUTable";
import { useParams } from "react-router-dom";
import AddItem from "./components/AddItem";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const previewImageSRC = useSelector((state) => state.product.previewImageSRC);
  const graphicURL = useSelector((state) => state.product.graphicURL);
  const notecardURL = useSelector((state) => state.product.notecardURL);
  const userType = useSelector((state) => state.user.user.type);
  const { orderID } = useParams();

  const type = catalogDetails?.breadCrumb;

  const [tabValue, setTabValue] = React.useState("1");
  const [productTitle, setProductTitle] = useState("");
  const [graphicUrlError, setGraphicUrlError] = useState(false);
  const [notecardUrlError, setNotecardUrlError] = useState(false);
  const [etsyListingID, setEtsyListingID] = useState("");
  const [amazonListingID, setAmazonListingID] = useState("");

  const [hellocustomCheckedGraphic, setHellocustomCheckedGraphic] =
    useState(false);
  const [hellocustomCheckedNotecard, setHellocustomCheckedNotecard] =
    useState(false);

  const uploadedGraphicImage = useSelector(
    (state) => state.product.uploadedGraphicImage
  );
  const uploadedNotecardImage = useSelector(
    (state) => state.product.uploadedNotecardImage
  );

  const noOfGraphics =
    type === "products"
      ? catalogDetails?.catalog?.no_of_graphics
      : catalogDetails.no_of_graphics;

  const previewImageIsUploaded =
    previewImageSRC?.src && !isEmptyObject(previewImageSRC.src) ? true : false;

  const graphicImageIsUploaded =
    uploadedGraphicImage?.src && !isEmptyObject(uploadedGraphicImage?.src)
      ? true
      : false;

  const notecardImageIsUploaded =
    uploadedNotecardImage?.src && !isEmptyObject(uploadedNotecardImage?.src)
      ? true
      : false;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (type === "products") {
      if (isImageUrl(catalogDetails?.uploadedData) === "url") {
        dispatch(setGraphicURL(catalogDetails?.uploadedData));
        dispatch(setUploadedGraphicImage({}, ""));
      } else {
        handleConvert(dispatch, catalogDetails?.uploadedData, "graphic");
      }

      if (noOfGraphics === 2) {
        if (isImageUrl(catalogDetails?.notecardData) === "url") {
          dispatch(setNotecardURL(catalogDetails?.notecardData));
          dispatch(setUploadedNotecardImage({}, ""));
        } else {
          handleConvert(dispatch, catalogDetails?.notecardData, "notecard");
        }
      }
    } else {
      dispatch(setUploadedGraphicImage({}, ""));
      dispatch(setUploadedNotecardImage({}, ""));
      dispatch(setGraphicURL(""));
      dispatch(setNotecardURL(""));
    }
    setProductTitle(catalogDetails?.title);
    getCartItems(dispatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onUploadPreviewImage(e) {
    const file = e.target.files[0];

    if (file) {
      const tempExtention = file.name.split(".");
      const fileExtention =
        tempExtention[tempExtention.length - 1].toLowerCase();
      const allowedFileExtentions = ["png", "jpg"];

      if (!allowedFileExtentions.includes(fileExtention)) {
        toastify(
          "warning",
          "Please upload valid file type. File type allowed: PNG, JPG"
        );
        return;
      }

      if (file.size > 20000000) {
        toastify("warning", "File size should be less than 20MB");
        return;
      }

      dispatch(setPreviewImageSRC(file, "preview-image.png"));
    }
  }

  const handleUrl = (e, type) => {
    const inputUrl = e.target.value;
    if (type === "graphic") {
      dispatch(setGraphicURL(inputUrl));

      if (inputUrl.trim() === "") {
        setGraphicUrlError(false);
      } else {
        setGraphicUrlError(!isValidURL(inputUrl));
      }
    } else {
      dispatch(setNotecardURL(inputUrl));

      if (inputUrl.trim() === "") {
        setNotecardUrlError(false);
      } else {
        setNotecardUrlError(!isValidURL(inputUrl));
      }
    }
  };

  const reset = () => {
    if (type === "products") {
      setProductTitle(catalogDetails.title);
      dispatch(setGraphicURL(""));
      dispatch(setNotecardURL(""));
      dispatch(setPreviewImageSRC({}, ""));
    } else {
      setHellocustomCheckedGraphic(false);
      setHellocustomCheckedNotecard(false);
    }
    dispatch(setUploadedGraphicImage({}, ""));
    dispatch(setUploadedNotecardImage({}, ""));
  };

  const isDisabled =
  noOfGraphics === 0
    ? true
    : noOfGraphics === 2
    ? (graphicImageIsUploaded || graphicURL || hellocustomCheckedGraphic) &&
      (notecardImageIsUploaded ||
        notecardURL ||
        hellocustomCheckedNotecard) &&
      !graphicUrlError &&
      !notecardUrlError
    : (graphicImageIsUploaded || graphicURL || hellocustomCheckedGraphic) &&
      !graphicUrlError;


  const handleGraphicCheckboxChange = (event) => {
    setHellocustomCheckedGraphic(event.target.checked);
    if (event.target.checked) {
      convertImageToFile(dispatch, helloCustomImage, "graphic");
    } else {
      dispatch(setUploadedGraphicImage({}, ""));
    }
    dispatch(setGraphicURL(""));
 
  };
  const handleNotecardCheckboxChange = (event) => {
    setHellocustomCheckedNotecard(event.target.checked);
    if (event.target.checked) {
      convertImageToFile(dispatch, helloCustomImage, "notecard");
    } else {
      dispatch(setUploadedNotecardImage({}, ""));
    }
    dispatch(setNotecardURL(""));
  };

  return (
    <Card style={{ padding: "20px", marginBottom: "24px" }}>
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        flexWrap={"wrap"}
        spacing={4}
      >
        <Box style={{ flex: 1 }}>
          <ImageContainer>
            {userType === "Seller" && type === "products" ? (
              <EditPreviewImage handlePreviewImage={onUploadPreviewImage} />
            ) : (
              ""
            )}
            {previewImageIsUploaded || type === "products" ? (
              <StyledCardMedia
                component="img"
                image={
                  previewImageIsUploaded
                    ? URL.createObjectURL(previewImageSRC?.src)
                    : catalogDetails?.images[0]
                }
                alt="preview"
              />
            ) : (
              <Carousel autoPlay={false}>
                {catalogDetails?.images?.map((image, index) => (
                  <ImageViewPopup
                    imageSRC={[image]}
                    sx={{ height: "100%", width: "100%", borderRadius: "10px" }}
                  />
                ))}
              </Carousel>
            )}
          </ImageContainer>
        </Box>

        <Box style={{ flex: 1 }}>
          <TabContext value={tabValue}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "white",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                // wrapped
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab label="Product Detail" value="1" />
                <Tab label="Shipping Cost" value="2" />
                <Tab label="FAQs" value="3" />
              </TabList>
            </Box>
            <TabPanel value={"1"}>
              <ProductDescription
                catalogDetails={
                  type === "products" ? catalogDetails?.catalog : catalogDetails
                }
              />
            </TabPanel>
            <TabPanel value={"2"}>
              <ShippingCost
                catalogDetails={
                  type === "products" ? catalogDetails?.catalog : catalogDetails
                }
              />
            </TabPanel>
            <TabPanel value={"3"}>
              <FAQs
                catalogDetails={
                  type === "products" ? catalogDetails?.catalog : catalogDetails
                }
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{ minHeight: "400px", width: "100%" }}
      >
        <Box sx={{ flex: 1 }}>
          {userType === "Seller" && (
            <>
              {!orderID && (
                <EditCatalogTitle
                  productTitle={productTitle}
                  onChange={(e) => {
                    setProductTitle(e.target.value);
                  }}
                />
              )}

              {noOfGraphics === 0 ? (
                ""
              ) : (
                <>
                  <AddGraphicImage
                    uploadedFile={uploadedGraphicImage}
                    deleteFile={() => dispatch(setUploadedGraphicImage({}, ""))}
                    type={"graphic"}
                    urlValue={graphicURL}
                    onURLChange={(e) => handleUrl(e, "graphic")}
                    urlError={graphicUrlError}
                    disableURL={
                      hellocustomCheckedGraphic
                        ? true
                        : !!graphicImageIsUploaded
                    }
                    hellocustomChecked={hellocustomCheckedGraphic}
                    handleCheckboxChange={handleGraphicCheckboxChange}
                    catalogDetails={
                      type === "products"
                        ? catalogDetails?.catalog
                        : catalogDetails
                    }
                    location={catalogDetails?.breadCrumb}
                    orderID={orderID}
                  />
                  {noOfGraphics === 2 && (
                    <AddGraphicImage
                      uploadedFile={uploadedNotecardImage}
                      deleteFile={() =>
                        dispatch(setUploadedNotecardImage({}, ""))
                      }
                      type={"notecard"}
                      urlValue={notecardURL}
                      onURLChange={(e) => handleUrl(e, "notecard")}
                      urlError={notecardUrlError}
                      disableURL={
                        hellocustomCheckedNotecard
                          ? true
                          : !!notecardImageIsUploaded
                      }
                      hellocustomChecked={hellocustomCheckedNotecard}
                      handleCheckboxChange={handleNotecardCheckboxChange}
                      catalogDetails={
                        type === "products"
                          ? catalogDetails?.catalog
                          : catalogDetails
                      }
                      location={catalogDetails?.breadCrumb}
                      orderID={orderID}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Box>
        {!orderID && type === "Catalog" ? (
          <Box
            sx={{
              flex: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              spacing={3}
            >
              <ProductLinks />
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: "#E6F7FF",
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 4,
                  color: "primary.main",
                }}
              >
                <Iconify icon="mdi:timer-sand-empty" width={40} />
                <Typography variant="body1">Processing Time</Typography>
                <Typography variant="h5">
                  {catalogDetails?.processing_time}
                </Typography>
              </Box>
              <LinkButtons
                etsyListingID={etsyListingID}
                setEtsyListingID={setEtsyListingID}
                amazonListingID={amazonListingID}
                setAmazonListingID={setAmazonListingID}
              />
            </Stack>
          </Box>
        ) : (
          <Box sx={{ flex: 1 }}>
            {catalogDetails?.etsy_product_skus?.length ? (
              <Box sx={{ my: 2 }}>
                <SKUTable />
              </Box>
            ) : (
              <></>
            )}
            <></>
          </Box>
        )}
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        flexWrap={"wrap"}
        alignItems={"center"}
        spacing={3}
      >
        {orderID ? (
          <AddItem />
        ) : type === "products" ? (
          <>
            <AddToCart disabled={!isDisabled} reset={reset} />
            <UpdateProduct
              disabled={!isDisabled}
              productTitle={productTitle}
            />
            <LinkToEtsy />
            <LinkToAmazon />
          </>
        ) : (
          <>
            <AddToCart disabled={!isDisabled} reset={reset} />
            <AddToProducts
              disabled={!isDisabled}
              productTitle={productTitle}
              reset={reset}
              etsyListingID={etsyListingID}
              amazonListingID={amazonListingID}
            />
          </>
        )}
      </Stack>
    </Card>
  );
};

export default ProductDetails;
