import React, { useEffect, useState } from "react";
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { loadingAction } from "src/redux/actions/userActions";
import request from "src/utils/request";
import { format } from "date-fns";
import Toolbar from "./Toolbar";
import { toastify } from "src/utils/toast";
import { formatHistoryData } from "./utils";
import Iconify from "src/components/iconify";

const ChargeHistory = () => {
  const dispatch = useDispatch();
  const [originalHistory, setOriginalHistory] = useState([]);
  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    getChargeHistory();
  }, []);

  const getChargeHistory = async () => {
    dispatch(loadingAction(true));

    try {
      const res = await request.get("/payments");

      if (res) {
        const data = res.data.data.payments;
        const formattedData = formatHistoryData(data);

        setHistory(formattedData);
        setOriginalHistory(formattedData);
        dispatch(loadingAction(false));
      }
    } catch (e) {
      dispatch(loadingAction(false));
      toastify("error", e.response.data.message);
      if (e.response.status === 404) {
        setHistory([]);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Card className="table">
      <Toolbar
        history={originalHistory}
        setHistory={setHistory}
        getChargeHistory={getChargeHistory}
      />
      <TableContainer component={Paper} style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Order ID #</TableCell>
              <TableCell align="center">Order Date</TableCell>
              <TableCell align="center">Amount Charged</TableCell>
              <TableCell align="center">Refunded Amount</TableCell>
              <TableCell align="center">Payment Mode</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.length === 0 ? (
              <TableRow sx={{ my: 3 }}>
                <TableCell align="center" colSpan={6}>
                  <Typography variant="body2">No results found.</Typography>
                </TableCell>
              </TableRow>
            ) : (
              history
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow hover key={item.order_id}>
                    <TableCell align="center">{item.order_id || "-"}</TableCell>
                    <TableCell align="center">
                      {format(new Date(item?.date), "MMMM dd, yyyy")}
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="p"
                        color={
                          item?.type === "debit" ? "error.dark" : "success.dark"
                        }
                      >
                        {item?.type === "debit" ? (
                          <Iconify icon="heroicons:minus-small-20-solid" />
                        ) : (
                          <Iconify icon="eva:plus-fill" />
                        )}
                        ${item.amount}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="p" color={"success.dark"}>
                        {item.refund_amount ? (
                          <>
                            <Iconify icon="eva:plus-fill" />$
                            {item.refund_amount}
                          </>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="p"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item?.payment_mode || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="p"
                        color={
                          item?.type === "debit" ? "error.dark" : "success.dark"
                        }
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item?.type}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={history?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default ChargeHistory;
