import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Chip,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { NavLink } from "react-router-dom";

const SubscriptionSection = ({ catalog }) => {
  const theme = useTheme();
  const [payAnnually, setPayAnnually] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const FeatureList = ({ title, features }) => (
    <Box
      sx={{
        flex: 1,
        bgcolor: "#F9FAFB",
        borderRadius: 2,
        textAlign: "left",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <List>
        {features.map((item, index) => (
          <ListItem
            sx={{ padding: catalog ? "2px" : "" }}
            key={index}
            disableGutters
          >
            <ListItemIcon sx={{ minWidth: "34px" }}>
              <Iconify
                icon="material-symbols-light:check-circle-outline-rounded"
                sx={{ color: "#1EDC7D", bgcolor: "1EDC7D1A" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={item}
              sx={{ "& span": { fontSize: "12px" } }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const primaryFeatures = [
    "Access to Our Strategic Product Types w/ Premium Discounts",
    "Access our Live Monthly Coaching Sessions + Presentation PDFs + Unlimited Replays",
    "Unlimited Access to ICP’s Strategic Product Trainings",
  ];

  const additionalFeatures = [
    "Access to all POD Business Growth Events Hosted By ICP",
    "Connection for Your Canva, Kittl, or HelloCustom Designs to Your Products for Order Management Ease",
    "Cancel anytime",
  ];
  return (
    <>
      <Stack alignItems="center" sx={{ mb: catalog ? 2 : 4 }}>
        <Box
          sx={{ width: catalog ? "80%" : { xs: "90%", sm: "75%", md: "60%" } }}
        >
          <Typography
            variant={catalog ? "h6" : isSmallScreen ? "h5" : "h3"}
            align="center"
            color="primary.main"
            fontWeight={800}
            gutterBottom
          >
            Inner Circle Prints Member Access
          </Typography>

          <Typography
            variant={catalog ? "subtitle2" : "subtitle1"}
            align="center"
          >
            You are about to make the best decision you have ever made for your
            POD business.
          </Typography>
        </Box>
      </Stack>

      <Card
        sx={{
          borderRadius: 3,
          boxShadow: 3,
          maxWidth: 1000,
          mx: "auto",
          border: "8px solid #2196F3",
        }}
      >
        <CardContent
          sx={{
            padding: catalog ? 2 : "",
            paddingBottom: catalog ? "4px !important" : "",
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Box
              sx={{
                flex: 1,
                textAlign: { xs: "center", md: "left" },
                minWidth: "280px",
              }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  border: `2px solid ${payAnnually ? "#4D5874 " : "#33BFFF"}`,
                  backgroundColor: payAnnually ? "#E0E3EB1A" : "#E3F5FF",
                  color: payAnnually ? "#4D5874" : "#33BFFF",
                  fontWeight: 500,
                  borderRadius: "8px",
                  padding: "4px 12px",
                  fontSize: "14px",
                  textAlign: "center",
                  mb: 2,
                }}
              >
                {payAnnually ? "Recommended" : "Most Popular"}
              </Box>
              <Typography variant="body2">
                {payAnnually ? "Annual" : "Monthly"} Member Access
              </Typography>

              <Box
                sx={{
                  minWidth: "220px",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography variant="h3" fontWeight={600}>
                  {payAnnually ? "$19" : "$29"}
                  <Typography component="span" variant="body1" fontWeight={500}>
                    / month
                  </Typography>
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                mt={2}
                sx={{
                  flexWrap: "nowrap",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: { xs: "center", sm: "flex-start" },
                  minWidth: "250px",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography variant="body2">Pay Monthly</Typography>
                <Switch
                  checked={payAnnually}
                  onChange={() => setPayAnnually(!payAnnually)}
                />
                <Typography variant="body2">Pay Annually</Typography>
                {payAnnually ? (
                  <Chip
                    label="SAVE 35%"
                    size="small"
                    sx={{
                      ml: 1,
                      backgroundColor: "#1EDC7D1A",
                      color: "#1EDC7D",
                    }}
                  />
                ) : (
                  <></>
                )}
              </Box>
              <NavLink to="/signup">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 3,
                    width: { xs: "70%", sm: "100%" },
                    fontSize: { xs: "0.75rem", sm: "0.875rem" },
                    padding: { xs: "4px 8px", sm: "6px 16px" },
                  }}
                >
                  Start Your 7 Day Free Trial
                </Button>
              </NavLink>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                width: "100%",
                paddingLeft: { xs: 0, md: 4 },
              }}
            >
              <FeatureList title="Included" features={primaryFeatures} />
              <FeatureList title="" features={additionalFeatures} />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default SubscriptionSection;
