import React, { useEffect, useMemo } from "react";
import Update from "./Update";
import DailyStats from "./UserStats";
import { format } from "date-fns";
import { getLastThreeMonths, myClock } from "./utils";
import { getPosts, getProductStats } from "./requests";
import { getScopesData } from "../scopes";
import { getWidgetsArray } from "./widgetConfig";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Container, Typography, Box } from "@mui/material";
import { setModalState } from "src/redux/actions/modalActions";
import { AppWidgetSummary } from "../../sections/dashboard/app";
import { setEtsyOrderList } from "src/redux/actions/orderActions";
import { getOrderStats } from "../orders/requests/icp/orderStats";
import { getPrintProviders } from "../users/requests";
import { getTaxDocument } from "../settings/document/request";
import { getCatalogsList } from "../catalog/request";
import ProductsLaunched from "./charts/ProductsLaunched";
import ProductsSold from "./charts/ProductsSold";
import FilterChartStats from "./FilterChartStats";
import { NavLink } from "react-router-dom";
import { getEtsyShop } from "../store/request";
import { getApps } from "../apps/request";

export default function DashboardAppPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const apps = useSelector((state) => state.oAuth.apps);
  const scopes = useSelector((state) => state.oAuth.scopes);
  const userType = useSelector((state) => state.user.user.type);
  const orderStats = useSelector((state) => state.orders.orderStats);
  const productStats = useSelector((state) => state.product.productStats);

  const firstName = useMemo(() => user?.name?.split(" ")[0], [user]);
  const widgets = useMemo(
    () => getWidgetsArray(userType, scopes, apps, orderStats),
    [userType, scopes, apps, orderStats]
  );
  const { start_month_year, end_month_year } = useMemo(
    () => getLastThreeMonths(),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      if (userType === "Developer") {
        getApps(dispatch);
        getScopesData(dispatch);
      } else {
        dispatch(setEtsyOrderList([]));
        getOrderStats(dispatch, userType);
        getPosts(dispatch);
        getCatalogsList(dispatch);
        if (userType === "Seller") {
          getProductStats(dispatch, { start_month_year, end_month_year });
          getEtsyShop(dispatch);
          getTaxDocument(dispatch);
        } else {
          getPrintProviders(dispatch, 1, 100);
        }
      }
      dispatch(setModalState(undefined));
      myClock();
    };

    fetchData();
  }, [dispatch, userType, start_month_year, end_month_year]);

  return (
    <Container maxWidth="2xl">
      <Grid container sx={{ mb: { xs: 2, md: 4 } }} alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" sx={{ typography: { xs: "h6", md: "h4" } }}>
            Hi {firstName}, let’s turn your POD dreams into a{" "}
            <Box component="span" sx={{ color: "primary.main" }}>
              reality!
            </Box>
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} textAlign={{ xs: "start", md: "end" }}>
          {userType === "Seller" ? (
            <Box
              sx={{ cursor: "pointer", "&:hover": { color: "text.primary" } }}
            >
              <NavLink to={"/apply"}>
                <Typography variant="body2">
                  Apply for 1-on-1 Coaching
                </Typography>
                <Typography variant="body2">
                  From a 7-Figure ICP Seller
                </Typography>
              </NavLink>
            </Box>
          ) : (
            <Typography variant="body2">
              {format(new Date(), "MMM dd, yyyy")} <span id="time"></span>
            </Typography>
          )}
        </Grid>
      </Grid>

      {userType === "Seller" && <FilterChartStats />}

      <Grid container spacing={2}>
        {userType === "Seller" ? (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <ProductsLaunched />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ProductsSold />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: "#E6F7FF",
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "text.primary",
                    fontWeight: 500,
                    textDecoration: "underline",
                  }}
                >
                  Fulfilled Orders
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ color: "text.primary", fontWeight: 700 }}
                >
                  {productStats?.fulfilled_orders ?? 0}
                </Typography>
              </Box>
            </Grid>
          </>
        ) : (
          widgets?.map((widget, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
              <AppWidgetSummary
                sx={{ height: "100%" }}
                title={widget.title}
                total={widget.total}
                card_color={widget.cardColor}
                icon_color={widget.iconColor}
                icon={widget.icon}
              />
            </Grid>
          ))
        )}
      </Grid>

      {userType !== "Developer" && (
        <>
          {userType === "Super Admin" && <DailyStats />}
          <Update />
        </>
      )}
    </Container>
  );
}
