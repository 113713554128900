import React from "react";
import {
  Typography,
  Button,
  Grid,
  CardMedia,
  Container,
  Stack,
  Box,
  Link,
  Divider,
} from "@mui/material";
import UserStories from "src/pages/home/sections/UserStories";
import { ArrowDropDown } from "@mui/icons-material";

const videos = [
  "https://player.vimeo.com/video/1058037304?h=2100e5211d&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  "https://player.vimeo.com/video/1058042124?h=e5fde13d32&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  "https://player.vimeo.com/video/1058045599?h=317cf25909&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
];

const TestimonialPage = () => {
  return (
    <>
      {/* Top "Apply now" Button */}
      <Stack direction={"row"} justifyContent={"flex-end"} m={2}>
        <Link
          href="https://form.typeform.com/to/aiDzx0Ep?typeform-source=www.canva.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <Button
            size="large"
            variant="contained"
            sx={{
              borderRadius: "25px",
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            Apply now
          </Button>
        </Link>
      </Stack>

      <Container maxWidth="lg" sx={{ my: 4 }}>
        {/* Responsive Stack for main content */}
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={4}
        >
          {/* Text Section */}
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h2"
              color="primary.main"
              sx={{ fontFamily: '"League Spartan", sans-serif', lineHeight: 1 }}
            >
              <strong>Meet Your</strong>
            </Typography>
            <Typography
              variant="h2"
              sx={{ fontFamily: '"League Spartan", sans-serif', lineHeight: 1 }}
            >
              7-Figure
            </Typography>
            <Typography
              variant="h2"
              color="primary.main"
              sx={{ fontFamily: '"League Spartan", sans-serif', lineHeight: 1 }}
            >
              <strong>POD Coach</strong>
            </Typography>

            <Typography
              variant="body1"
              paragraph
              sx={{ fontFamily: '"League Spartan", sans-serif' }}
              mt={2}
              color={"#000"}
              fontWeight={600}
            >
              At Inner Circle Prints, our coaches stand apart from the rest in
              the print-on-demand space. They aren’t just people who made a few
              thousand sales and pivoted to coaching as an escape from “real
              work”.
            </Typography>

            <Typography
              variant="body1"
              paragraph
              sx={{ fontFamily: '"League Spartan", sans-serif' }}
              color={"#000"}
              fontWeight={600}
            >
              Each coach was personally selected by me, Jesse Anselm (founder of
              ICP), after proving themselves as top sellers in my program.
              They’ve generated tens of thousands of sales and have successfully
              guided dozens of clients to six-figure success.
            </Typography>

            <Typography
              variant="body1"
              paragraph
              sx={{ fontFamily: '"League Spartan", sans-serif' }}
              color={"#000"}
              fontWeight={600}
            >
              What truly sets them apart is their hands-on approach. They’re not
              just teaching from past experience—they’re actively building their
              own businesses right alongside you. Their insights are fresh,
              their strategies are tested, and they’re thriving in the POD space
              right now.
            </Typography>

            <Typography
              variant="body1"
              paragraph
              sx={{ fontFamily: '"League Spartan", sans-serif' }}
              color={"#000"}
              fontWeight={600}
            >
              So go watch these videos and get to know them a little better.
              Then take a confident leap and click that “Apply Now” button.
            </Typography>

            <Typography
              variant="body1"
              color="primary"
              fontWeight="bold"
              gutterBottom
            >
              Your POD dreams are about to become a reality.
            </Typography>

            <Link
              href="https://form.typeform.com/to/aiDzx0Ep?typeform-source=www.canva.com"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <Button variant="contained" sx={{ mt: 2 }}>
                Apply Now
              </Button>
            </Link>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "0px",
            }}
          >
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderStyle: "dashed",
                height: "100%",
                borderWidth: "2px",
              }}
            />
            <ArrowDropDown
              sx={{ fontSize: 30, paddingLeft: "2px", color: "gray" }}
            />
          </Box>

          <Box sx={{ flex: 1, width: "100%" }}>
            <Grid container spacing={3}>
              {videos?.map((video, index) => (
               <Grid item xs={12} key={index}>
               {index === 1 ? (
                 <Stack
                   direction={"row"}
                   justifyContent="space-between"
                   alignItems="center"
                   spacing={{ xs: 0, md: 8 }}
                 >
                   <Typography
                     variant="h3"
                     sx={{
                       fontFamily: '"League Spartan", sans-serif',
                       lineHeight: "30px",
                       display: { xs: "none", md: "block" }, // hide on mobile, show on md+
                     }}
                   >
                     <span style={{ fontWeight: "bold", color: "#02B2FE" }}>
                       Client
                     </span>
                     <br />
                     <span style={{ fontWeight: "bold", color: "#000" }}>
                       Results
                     </span>
                     <br />
                     <span style={{ fontWeight: "bold", color: "#02B2FE" }}>
                       Below
                     </span>
                   </Typography>
                   <CardMedia
                     component="iframe"
                     src={video}
                     sx={{
                       width: "100%",
                       height: { xs: 220, sm: 280, md: 200 },
                     }}
                     frameBorder="0"
                     allowFullScreen
                   />
                 </Stack>
               ) : (
                 <CardMedia
                   component="iframe"
                   src={video}
                   sx={{
                     width: "100%",
                     height: { xs: 220, sm: 280, md: 200 },
                   }}
                   frameBorder="0"
                   allowFullScreen
                 />
               )}
             </Grid>
             
              ))}
            </Grid>
          </Box>
        </Stack>
      </Container>

      <Box sx={{ mt: { xs: 0, md: 8 }, py: { xs: 0, md: 8 } }}>
        <UserStories
          title={
            <Typography
              variant="h2"
              sx={{ fontFamily: '"League Spartan", sans-serif' }}
            >
              <span style={{ fontWeight: "bold", color: "#02B2FE" }}>
                Unheard of
              </span>{" "}
              <span style={{ fontWeight: "bold", color: "#000" }}>Client</span>{" "}
              <span style={{ fontWeight: "bold", color: "#02B2FE" }}>
                Results
              </span>
            </Typography>
          }
        />
      </Box>
    </>
  );
};

export default TestimonialPage;
