import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Button, Box } from "@mui/material";
import { endTrial } from "../subscription-management/requests";

const TrialEndCard = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loadingButton);
  const subscription = useSelector((state) => state.user.subscription);

  const payload = {
    subscription_id: subscription?.subscription_id,
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <Card
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          maxWidth: 400,
          boxShadow: 3,
        }}
      >
        <LockIcon sx={{ fontSize: 40, color: "grey.600", mb: 2 }} />
        <Typography variant="h6" fontWeight="bold">
          This feature is only available after your free trial has ended.
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, mb: 3 }}>
          Would you like to skip the free trial and access our trainings now?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => endTrial(dispatch, payload)}
        >
          {loading ? "Loading..." : "End Trial"}
        </Button>
      </Card>
    </Box>
  );
};

export default TrialEndCard;
