import React from "react";
import {
  Grid,
  CardMedia,
  Typography,
  Box,
  Container,
  Stack,
  Card,
} from "@mui/material";
import Header from "../header";
import { fCurrency } from "src/utils/formatNumber";
import catalog1 from "../../../assets/home/catalog/1.webp";
import catalog2 from "../../../assets/home/catalog/2.webp";
import catalog3 from "../../../assets/home/catalog/3.webp";
import catalog4 from "../../../assets/home/catalog/4.webp";
import catalog5 from "../../../assets/home/catalog/5.webp";
import catalog6 from "../../../assets/home/catalog/6.webp";
import catalog7 from "../../../assets/home/catalog/7.webp";
import catalog8 from "../../../assets/home/catalog/8.webp";
import catalog9 from "../../../assets/home/catalog/9.webp";
import catalog10 from "../../../assets/home/catalog/10.webp";
import catalog11 from "../../../assets/home/catalog/11.webp";
import catalog12 from "../../../assets/home/catalog/12.webp";
import catalog13 from "../../../assets/home/catalog/13.webp";
import newProduct from "../../../assets/home/catalog/new_product.png";
import MyImage from "../../../components/lazy-load-image/MyImage";

const products = [
  {
    title: "20oz SKINNY TUMBLER",
    price: "$9.67",
    shipping: "+ SHIPPING",
    image: catalog1,
  },
  {
    title: "16oz FROSTED CUP",
    price: "$8.99",
    shipping: "+ SHIPPING",
    image: catalog2,
  },
  {
    title: "Accent Coffee Mug",
    price: "$8.99",
    shipping: "+ SHIPPING",
    image: catalog4,
  },
  {
    title: "Ornament + Gift Box",
    price: "$6.09",
    shipping: "+ SHIPPING",
    image: catalog3,
  },
  {
    title: "Necklace + Gift Box",
    price: "$7.79",
    shipping: "+ SHIPPING",
    image: catalog8,
  },
  {
    title: "Bracelet + Gift Box",
    price: "$8.79",
    shipping: "+ SHIPPING",
    image: catalog9,
  },
  {
    title: "Keychain + Gift Box",
    price: "$7.79",
    shipping: "+ SHIPPING",
    image: catalog10,
  },
  {
    title: "Framed Prints",
    price: "$12.99",
    shipping: "+ SHIPPING",
    image: catalog5,
  },
  {
    title: "Wooden Signs",
    price: "$29.99",
    shipping: "+ SHIPPING",
    image: catalog7,
  },
  { title: "Prints", price: "$9.99", shipping: "+ SHIPPING", image: catalog6 },
  {
    title: "Baby Onesie",
    price: "$8.90",
    shipping: "+ SHIPPING",
    image: catalog11,
  },
  {
    title: "Toddler Shirt",
    price: "$9.90",
    shipping: "+ SHIPPING",
    image: catalog12,
  },
  {
    title: "Youth Shirt",
    price: "$9.90",
    shipping: "+ SHIPPING",
    image: catalog13,
  },
  {
    title: "New Product",
    price: "",
    shipping: "Coming soon!",
    image: newProduct,
  },
  {
    title: "New Product",
    price: "",
    shipping: "Coming soon!",
    image: newProduct,
  },
];

const highlightText = (
  <Box sx={{ textAlign: "left", px: 2, py: { xs: 2, md: 4 } }}>
    <Typography variant="h5" fontWeight="bold">
      <Box component="span" sx={{ color: "primary.main" }}>
        Inner Circle Prints’ user results
      </Box>{" "}
      far exceed those of any other POD provider, thanks to our{" "}
      <Box component="span" sx={{ color: "primary.main", mx: 1 }}>
        unique focus.
      </Box>{" "}
      The{" "}
      <Box component="span" sx={{ fontWeight: "bold" }}>
        AVERAGE
      </Box>{" "}
      active Inner Circle Prints user generated just over 1,000 sales in 2024,
      while our top users achieved between 10,000 and 30,000 sales.
      <Box component="span" sx={{ color: "primary.main", ml: 1 }}>
        This is the power of strategic product types and superior POD education!
      </Box>
    </Typography>
  </Box>
);

const CatalogImages = () => {
  return (
    <>
      <Header />
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Grid container spacing={2}>
          {/* Left Section */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: { md: "sticky", xs: "static" },
                top: 0,
                minHeight: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
              }}
            >
              {highlightText}
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                {products.map((product, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Card sx={{ height: "100%" }}>
                      <Box sx={{ pt: "100%", position: "relative" }}>
                        <MyImage
                          image={[product.image]}
                          sx={{
                            top: 0,
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>

                      <Stack spacing={1} sx={{ p: 3 }}>
                        <Typography variant="h6" noWrap>
                          {product.title}
                        </Typography>
                        {product.price && (
                          <Typography variant="subtitle1" color={"primary"}>
                            Price {fCurrency(product.price)}
                          </Typography>
                        )}
                        <Typography variant="subtitle1" color={"primary"}>
                          {product.shipping}
                        </Typography>
                      </Stack>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {/* Video Section */}
              <Box sx={{ width: "100%", my: 4 }}>
                <CardMedia
                  component="iframe"
                  src="https://player.vimeo.com/video/1058375493?h=14aace0be6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  height="400"
                  frameBorder="0"
                  allowFullScreen
                  sx={{ width: "100%", borderRadius: 2 }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CatalogImages;
