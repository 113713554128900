const defaultState = {
  trainingsData: [],
  trainingSections: [],
  trainingDetail: {},
  tutorialsData: [],
  tutorialsDetail: {},
  updates: [],
};

const educationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "setTrainingsData":
      return {
        ...state,
        trainingsData: action.payload,
      };

    case "setTrainingSections":
      return {
        ...state,
        trainingSections: action.payload,
      };
    case "setTrainingsDetail":
      return {
        ...state,
        trainingDetail: action.payload,
      };

    case "setTutorialsData":
      return {
        ...state,
        tutorialsData: action.payload,
      };

    case "setTutorialsDetail":
      return {
        ...state,
        tutorialsDetail: action.payload,
      };

    case "setUpdates":
      return {
        ...state,
        updates: action.payload,
      };

    default:
      return state;
  }
};

export default educationReducer;
