import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";

const CountdownTimer = ({ targetDate }) => {
  const getTargetDate = () => {
    if (targetDate) {
      return new Date(targetDate);
    }

    const storedDate = localStorage.getItem("countdownTargetDate");
    if (storedDate) {
      return new Date(storedDate);
    } else {
      const newTargetDate = new Date();
      newTargetDate.setDate(newTargetDate.getDate() + 30);
      localStorage.setItem("countdownTargetDate", newTargetDate.toISOString());
      return newTargetDate;
    }
  };

  const [countdownTarget, setCountdownTarget] = useState(getTargetDate);
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    setCountdownTarget(getTargetDate);
  }, [targetDate]);

  const calculateTimeLeft = () => {
    const difference = countdownTarget.getTime() - new Date().getTime();
    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [countdownTarget]);

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center" mt={0.5}>
      {Object.entries(timeLeft).map(([unit, value], index, array) => (
        <React.Fragment key={unit}>
          <Grid item>
            <Box
              sx={{
                width: 24,
                height: 24,
                backgroundColor: "primary.main",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
              }}
            >
              <Typography variant="caption">
                {String(value).padStart(2, "0")}
              </Typography>
            </Box>
          </Grid>
          {index < array.length - 1 && (
            <Grid item>
              <Typography variant="h6" color="primary.main">:</Typography>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default CountdownTimer;
