import React, { useEffect } from "react";
import Router from "./routes";
import ThemeProvider from "./theme";
import ScrollToTop from "./components/scroll-to-top";
import ModalProvider from "./components/modal/modal";
import { Backdrop } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { setModalState } from "./redux/actions/modalActions";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "./assets/loader.gif";
import {
  loadingBtn2Action,
  loadingBtnAction,
} from "./redux/actions/userActions";

export default function App() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setModalState(undefined));
      dispatch(loadingBtnAction(false));
      dispatch(loadingBtn2Action(false));
    }
  }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ToastContainer />
        <ModalProvider />
        <ScrollToTop />
        {isLoading && (
          <div className="loader">
            <Backdrop sx={{ zIndex: 2000 }} open={true}>
              <img
                src={loader}
                alt="loader"
                style={{ width: "80px", height: "80px" }}
              />
            </Backdrop>
          </div>
        )}

        <React.Suspense
          fallback={
            <div className="over-lay">
              <div>
                <span>Loading</span>
              </div>
            </div>
          }
        >
          <Router />
        </React.Suspense>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
