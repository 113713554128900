import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Iconify from "../iconify";
import Variations from "./variations";
import Validations from "./Validations";
import ImageViewPopup from "../image-viewer/ImageViewPopup";
import DeleteItem from "src/sections/modals/order/DeleteItem";
import OrderGraphics from "src/sections/modals/order/orderGraphics";
import DuplicateItem from "src/sections/modals/order/DuplicateItem";
import DynamicMockupEditor from "../mockup-editor/DynamicMockupEditor";
import {
  checkURL,
  getLink,
  getUploadedImage,
} from "src/sections/modals/order/utils";
import { findMockupUUID } from "./utils";
import { BackupSharp } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { updateOrder } from "src/pages/orders/requests/icp/updateOrder";
import { modifyOrderItems } from "src/pages/orders/requests/icp/orderItemActions";

function NestedTable({ order, orderStatus }) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedImageType, setSelectedImageType] = useState({});
  const [itemForUpload, setItemForUpload] = useState(null);
  const [uploadMenuAnchor, setUploadMenuAnchor] = useState(null);
  const [mockupUUID, setMockupUUID] = useState(null);

  const [orders, setOrders] = useState(
    order?.orderDetails ? order.orderDetails : []
  );

  useEffect(() => {
    if (order?.orderDetails) {
      setOrders(order.orderDetails);
    }
  }, [order.orderDetails]);

  const disableUpload = (item) => {
    if (userType === "Super Admin") return true;

    if (
      item?.catalog?.id === 50 ||
      item?.catalog?.id === 51 ||
      item?.catalog?.id === 52 ||
      item?.catalog?.id === 54
    ) {
      return item?.personalization_details === null ? true : false;
    }

    return orderStatus === "on hold" || order?.is_locked === 0 ? false : true;
  };

  const handleOpenMenu = (event, item) => {
    setSelectedItem(item);
    const id = findMockupUUID(item);
    setMockupUUID(id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenUploadTypeMenu = (event, item) => {
    setItemForUpload(item);
    setUploadMenuAnchor(event.currentTarget);
  };

  const handleCloseUploadTypeMenu = () => {
    setUploadMenuAnchor(null);
    setItemForUpload(null);
  };

  const handleSelectUploadType = (type, item) => {
    setSelectedImageType((prev) => ({ ...prev, [item.id]: type }));
    setUploadMenuAnchor(null);
    document.getElementById(`upload-${item.id}`).click();
  };

  const handleFileUpload = (event, item) => {
    const file = event.target.files[0];
    if (file) {
      const selectedType = selectedImageType[item.id] || "graphic_image";

      updateOrder(file, item?.id, dispatch, userType, selectedType);
    }
  };

  return (
    <>
      <TableContainer component={Paper} style={{ overflowX: "auto" }}>
        <Table sx={{ minWidth: isMobile ? "100%" : 900 }}>
          <TableHead>
            <TableRow
              sx={{
                "& th:first-of-type": {
                  borderTopLeftRadius: "16px",
                },
                "& th:last-of-type": {
                  borderTopRightRadius: "16px",
                },
              }}
            >
              <TableCell align="center">Thumbnail</TableCell>
              <TableCell align="left">Product</TableCell>
              <TableCell align="center">Design Link or Graphic</TableCell>
              <TableCell align="center">Upload Finalized Graphic</TableCell>
              <TableCell align="center">Finalized Graphic</TableCell>
              <TableCell align="left">Graphic Dimensions</TableCell>
              <TableCell align="left">Variations</TableCell>
              {(orderStatus === "shipped" || orderStatus === "fulfilled") && (
                <TableCell align="center" sx={{ minWidth: "200px" }}>
                  Tracking ID
                </TableCell>
              )}
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.map((item) => {
              return (
                <TableRow
                  key={item.id}
                  sx={{
                    backgroundColor:
                      item?.catalog?.print_provider === null ? "" : "#F0FFFF",
                  }}
                >
                  <TableCell align="center">
                    <ImageViewPopup
                      imageSRC={[
                        item.preview_image === null
                          ? item.catalog.images[0]
                          : item.preview_image,
                      ]}
                    />
                  </TableCell>

                  <TableCell align="left">
                    <Typography variant="body2">
                      {item.catalog.title}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    {item?.graphic_image === null ? (
                      <>-</>
                    ) : item?.catalog?.no_of_graphics === 0 ? (
                      <>-</>
                    ) : item?.catalog?.no_of_graphics === 1 ? (
                      checkURL(item.graphic_image) === "url" ? (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ fontSize: "0.75rem" }}
                          onClick={() =>
                            window.open(getLink(item.graphic_image), "_blank")
                          }
                          disabled={userType === "Seller" ? false : true}
                        >
                          Edit Graphic
                        </Button>
                      ) : (
                        <ImageViewPopup
                          imageSRC={[getUploadedImage(item, "graphic")]}
                          overlap
                        />
                      )
                    ) : (
                      item?.catalog?.no_of_graphics === 2 && (
                        <>
                          {checkURL(item?.graphic_image) === "url" ||
                          checkURL(item?.notecard_image) === "url" ? (
                            <Button
                              variant="contained"
                              size="small"
                              disabled={userType === "Seller" ? false : true}
                              sx={{ fontSize: "0.75rem" }}
                              onClick={() =>
                                dispatch(
                                  setModalState(<OrderGraphics item={item} />)
                                )
                              }
                            >
                              View Graphics
                            </Button>
                          ) : (
                            <ImageViewPopup
                              imageSRC={getUploadedImage(item)}
                              overlap
                            />
                          )}
                        </>
                      )
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {item?.catalog?.no_of_graphics === 0 ? (
                      <>-</>
                    ) : item?.catalog?.no_of_graphics === 1 ? (
                      <>
                        <input
                          type="file"
                          id={`upload-${item.id}`}
                          style={{ display: "none" }}
                          onChange={(e) => handleFileUpload(e, item)}
                        />

                        <label htmlFor={`upload-${item.id}`}>
                          <IconButton
                            component="span"
                            color="primary"
                            disabled={disableUpload(item)}
                          >
                            <BackupSharp />
                          </IconButton>
                        </label>
                      </>
                    ) : (
                      item?.catalog?.no_of_graphics === 2 && (
                        <>
                          <input
                            type="file"
                            id={`upload-${item.id}`}
                            style={{ display: "none" }}
                            onChange={(e) => handleFileUpload(e, item)}
                          />

                          <IconButton
                            color="primary"
                            disabled={disableUpload(item)}
                            onClick={(e) => handleOpenUploadTypeMenu(e, item)}
                          >
                            <BackupSharp />
                          </IconButton>
                        </>
                      )
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {item?.catalog?.no_of_graphics === 0 ? (
                      <>-</>
                    ) : (
                      <ImageViewPopup
                        imageSRC={getUploadedImage(item)}
                        overlap
                      />
                    )}
                  </TableCell>

                  <TableCell align="left">
                    {item?.catalog?.no_of_graphics === 0 ? (
                      <>-</>
                    ) : (
                      <Validations item={item} />
                    )}
                  </TableCell>

                  <TableCell align="left">
                    <Variations item={item} />
                  </TableCell>

                  {(orderStatus === "shipped" ||
                    orderStatus === "fulfilled") && (
                    <TableCell align="center" sx={{ minWidth: "200px" }}>
                      <Typography variant="body2">
                        {item?.tracking_id ? item?.tracking_id : "-"}
                      </Typography>
                    </TableCell>
                  )}

                  <TableCell align="right">
                    <IconButton
                      size="large"
                      color="inherit"
                      onClick={(e) => handleOpenMenu(e, item)}
                    >
                      <Iconify icon={"eva:more-vertical-fill"} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}

            {orders.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={order?.is_etsy === 1 ? 7 : 6}
                  align="center"
                >
                  <Typography variant="h6" paragraph>
                    There are no items in this order.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: "auto",
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          sx={{ color: "primary.main" }}
          disabled={disableUpload(selectedItem)}
          onClick={() => {
            handleCloseMenu();
            dispatch(
              setModalState(
                <DynamicMockupEditor
                  orderID={selectedItem?.id}
                  mockupUUID={mockupUUID}
                  type={"graphic"}
                  selected={"graphic_image"}
                  place={"orders"}
                />,
                true,
                { paddingLeft: 0 }
              )
            );
          }}
        >
          <Iconify icon={"eva:eye-outline"} sx={{ mr: 2 }} />
          {selectedItem?.catalog?.no_of_graphics === 2
            ? "Pendant Mockup Preview"
            : "Mockup Preview"}
        </MenuItem>
        {selectedItem?.catalog?.no_of_graphics === 2 && (
          <MenuItem
            sx={{ color: "primary.main" }}
            disabled={disableUpload(selectedItem)}
            onClick={() => {
              handleCloseMenu();
              dispatch(
                setModalState(
                  <DynamicMockupEditor
                    orderID={selectedItem?.id}
                    mockupUUID={"fea2a63e-445d-4f0c-8377-142794245a0c"}
                    type={"notecard"}
                    selected={"notecard_image"}
                    place={"orders"}
                  />,
                  true,
                  { paddingLeft: 0 }
                )
              );
            }}
          >
            <Iconify icon={"eva:eye-outline"} sx={{ mr: 2 }} />
            Notecard Mockup Preview
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            dispatch(
              setModalState(
                <DuplicateItem
                  duplicateItem={() =>
                    modifyOrderItems(
                      dispatch,
                      userType,
                      selectedItem?.id,
                      "duplicate"
                    )
                  }
                />
              )
            );
          }}
        >
          <Iconify icon="ion:duplicate" sx={{ mr: 2 }} />
          Duplicate
        </MenuItem>

        <MenuItem
          sx={{ color: "error.main" }}
          onClick={() => {
            handleCloseMenu();
            dispatch(
              setModalState(
                <DeleteItem
                  deleteItem={() =>
                    modifyOrderItems(
                      dispatch,
                      userType,
                      selectedItem?.id,
                      "delete"
                    )
                  }
                />
              )
            );
          }}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <Menu
        anchorEl={uploadMenuAnchor}
        open={Boolean(uploadMenuAnchor)}
        onClose={handleCloseUploadTypeMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => handleSelectUploadType("graphic_image", itemForUpload)}
        >
          Graphic
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleSelectUploadType("notecard_image", itemForUpload)
          }
        >
          Notecard
        </MenuItem>
      </Menu>
    </>
  );
}

export default NestedTable;
