import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { formatDateByPattern } from "src/utils/formatDate";
import { DatePicker } from "@mui/x-date-pickers";
import { loadingBtn2Action } from "src/redux/actions/userActions";
import { getProductStats } from "./requests";

const getDefaultDates = () => {
  const endDate = new Date();
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 2); // Set to last 3 months including current
  return { startDate, endDate };
};

const FilterChartStats = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton2);
  const [dates, setDates] = useState(getDefaultDates());

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setDates(getDefaultDates()); // Ensure the component initializes with correct default values
  }, []);

  return (
    <Box sx={{ width: "100%", my: 2 }}>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        alignItems={isMobile ? "flex-start" : "center"}
      >
        <DatePicker
          label="Start Month"
          sx={{ width: isMobile ? "100%" : "auto" }}
          views={["month", "year"]}
          onChange={(newValue) =>
            setDates((prev) => ({ ...prev, startDate: newValue }))
          }
          value={dates.startDate}
          inputFormat="MMMM/yyyy"
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
        <DatePicker
          label="End Month"
          views={["month", "year"]}
          sx={{ width: isMobile ? "100%" : "auto" }}
          onChange={(newValue) =>
            setDates((prev) => ({ ...prev, endDate: newValue }))
          }
          value={dates.endDate}
          inputFormat="MMMM/yyyy"
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
        <Box
          sx={{
            width: isMobile ? "100%" : "auto",
          }}
        >
          <LoadingButton
            variant="contained"
            size="small"
            fullWidth={isMobile}
            loading={isLoading}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
            onClick={() => {
              const formattedStartDate = formatDateByPattern(
                new Date(dates.startDate),
                "YYYY-MM"
              );
              const formattedEndDate = formatDateByPattern(
                new Date(dates.endDate),
                "YYYY-MM"
              );
              const payload = {
                start_month_year: formattedStartDate,
                end_month_year: formattedEndDate,
              };

              dispatch(loadingBtn2Action(true));
              getProductStats(dispatch, payload);
            }}
          >
            Filter
          </LoadingButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default FilterChartStats;
