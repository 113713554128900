import React, { useRef } from "react";
import { Box, Button } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { setPreviewImageSRC } from "src/redux/actions/productActions";
import { useDispatch } from "react-redux";

const AddPreviewImage = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box sx={{ my: 2 }}>
      <Button
        variant="contained"
        startIcon={<Upload />}
        size="small"
        onClick={handleButtonClick}
      >
        Upload Product Thumbnail
      </Button>
      <input
        ref={fileInputRef}
        accept="image/*"
        type="file"
        hidden
        onChange={(e) => {
          const file = e.target.files[0];

          if (file) {
            dispatch(setPreviewImageSRC(file, file.name));
          }
        }}
      />
    </Box>
  );
};

export default AddPreviewImage;
