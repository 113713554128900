import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { updateNotes } from "./request";
import { LoadingButton } from "@mui/lab";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fullToolbarOptions } from "../../updates/CreateUpdate";

const UpdateMeetingNote = ({ data, setNotes }) => {
  const dispatch = useDispatch();
  const userStats = useSelector((state) => state.user.userStats);
  const loading = useSelector((state) => state.user.loadingButton);
  const [editorValue, setEditorValue] = useState(data?.notes ?? "");

  const handleSubmit = (e) => {
    e.preventDefault();
    let date = e.target.elements.meeting_date.value;
    let title = e.target.elements.title.value;

    let payload = {
      note_id: data.id,
      user_id: userStats.userData.id,
      title: title,
      note: editorValue,
      date: date,
    };

    updateNotes(dispatch, payload, setNotes, userStats.userData.id);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          Add Meeting Note
        </Typography>
        <Stack direction="column" spacing={3} mt={3}>
          <Stack
            direction={"row"}
            alignContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <InputLabel>Date:</InputLabel>
            <TextField
              type="date"
              name="meeting_date"
              variant="standard"
              defaultValue={data.date}
              //   inputProps={{ min: new Date().toISOString().split("T")[0] }}
            />
          </Stack>

          <TextField
            type="text"
            name="title"
            label="Add Title"
            defaultValue={data.title}
            fullWidth
          />

          <ReactQuill
            theme="snow"
            placeholder="Add Notes"
            modules={{ toolbar: fullToolbarOptions }}
            value={editorValue}
            onChange={setEditorValue}
            style={{ height: "120px" }}
          />
        </Stack>
        <Stack direction="row" justifyContent={"flex-end"} mt={10} spacing={3}>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => dispatch(setModalState(undefined))}
          >
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            loading={loading}
            loadingIndicator={<CircularProgress size={12} color="inherit" />}
            variant="contained"
          >
            Update
          </LoadingButton>
        </Stack>
      </form>
    </Container>
  );
};

export default UpdateMeetingNote;
