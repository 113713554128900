import { Add } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StyledButton from "src/components/button/StyledButton";
import { loadingBtnAction } from "src/redux/actions/userActions";
import request from "src/utils/request";
import { toastify } from "src/utils/toast";

const AddItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderID } = useParams();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const addItemLoading = useSelector((state) => state.user.loadingButton);

  const graphicURL = useSelector((state) => state.product.graphicURL);
  const notecardURL = useSelector((state) => state.product.notecardURL);

  const uploadedGraphicImage = useSelector(
    (state) => state.product.uploadedGraphicImage
  );
  const uploadedNotecardImage = useSelector(
    (state) => state.product.uploadedNotecardImage
  );
  const type = catalogDetails?.breadCrumb;

  const noOfGraphics =
    type === "products"
      ? catalogDetails?.catalog?.no_of_graphics
      : catalogDetails.no_of_graphics;

  const addItemToOrder = async () => {
    let catalogID =
      type === "products" ? catalogDetails?.catalog?.id : catalogDetails?.id;

    let formData = new FormData();
    formData.append("catalog_id", catalogID);
    formData.append("order_id", orderID);

    if (graphicURL) {
      formData.append("url", graphicURL);
    } else {
      formData.append("graphic_image", uploadedGraphicImage?.src);
    }

    if (noOfGraphics === 2) {
      if (notecardURL) {
        formData.append("notecard_url", notecardURL);
      } else {
        formData.append("notecard_image", uploadedNotecardImage?.src);
      }
    }

    dispatch(loadingBtnAction(true));
    try {
      const res = await request.post("/orders/item/add", formData);

      if (res) {
        dispatch(loadingBtnAction(false));
        toastify("success", res.data.message);
        navigate("/dashboard/orders");
      }
    } catch (e) {
      dispatch(loadingBtnAction(false));
      toastify("error", e.response.data.message);
    }
  };

  return (
    <StyledButton
      title={"Add Item"}
      disabled={false}
      onClick={addItemToOrder}
      loading={addItemLoading}
      startIcon={<Add />}
    />
  );
};

export default AddItem;
